/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Jcob
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

// import moduleTodo from './todo/moduleTodo.js'
// import moduleCalendar from './calendar/moduleCalendar.js'
// import moduleChat from './chat/moduleChat.js'
// import moduleEmail from './email/moduleEmail.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleType from './type/moduleType.js'
import modulePayment from './payment/modulePayment.js'
import moduleAdminReview from './adminReview/moduleAdminReview.js'
import moduleExam from './exam/moduleExam.js'
import moduleBook from './book/moduleBook.js'
import moduleBug from './bug/moduleBug.js'
import moduleAdmin from './admin/moduleAdmin.js'
import moduleMain from './main/moduleMain.js'
import moduleProduct from './product/moduleProduct.js'
import moduleConsulting from './consulting/moduleConsulting.js'
import moduleAiExam from './ai-exam/moduleAiExam.js'
import moduleSchoolTestPaper from './school-test-paper/moduleSchoolTestPaper.js'

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
      main: moduleMain,
      auth: moduleAuth,
      admin: moduleAdmin,
      type: moduleType,
      payment: modulePayment,
      adminReview: moduleAdminReview,
      exam: moduleExam,
      book: moduleBook,
      bug: moduleBug,
      product: moduleProduct,
      consult: moduleConsulting,
      aiExam: moduleAiExam,
      schoolTestPaper: moduleSchoolTestPaper,
    },
    strict: process.env.NODE_ENV !== 'production'
})
