/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  examList: [],
  activeExam: null,
  bugQuestionList: [],
  doneList: [],
  questionList: [],
  activeQuestion: null,
  activeQuestionIndex: 0,
  lastQuestion: null,
  activeSelect: {
    name: '전체',
    grade_id: '',
    subject_type_id: 0
  },
  activeTab: {
    name: '전체',
    grade_id: ''
  }
}
