/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  // 2023-03-28 edit, add 
  getName: (state) => state.name,
  getPeriod: (state) => state.period,
  getCost: (state) => state.cost,
  getTotalSolved: (state) => state.totalSolved,
  getGrade: (state) => state.grade,
  getTimePerday: (state) => state.timePerDay,
  getTotalTime: (state) => state.totalTime,
  getRecommendedSchoolList: (state) => state.recommendedSchoolList,
  getRecommendedMajorList: (state) => state.recommendedMajorList,
  getUpgradeRecommendedSchoolList: (state) =>
    state.upgradeRecommendedSchoolList,
  getUpgradeRecommendedMajorList: (state) =>
    state.upgradeRecommendedMajorList,


    getOs: (state) => state.os,
}
