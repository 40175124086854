/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import axios from "../../http/axios/index.js"
import state from "../state.js"

export default {
  SET_TYPE_LIST(state, payload) {
    state.typeList = payload
    // console.log(state.typeList)
  },
  SET_USER_TYPE_LIST(state, payload) {
    // console.log(payload)
    for(var i=0; i<2; i++) {
      var filter = payload.filter((item) => {
        return item.state == (Number(i) + 5)
      })
      if(i == 0) state.userList = filter
      if(i == 1) state.doneList = filter
    }
  },
  SET_QUESTION_LIST(state, payload) {
    state.questionList = payload
  },
  SET_ACTIVE_QUESTION(state, payload) {
    state.activeQuestion = payload
  },
  SET_ACTIVE_QUESTION_INDEX(state, payload) {
    state.activeQuestionIndex = payload
  },
  SET_ACTIVE_EXAM(state, payload) {
    state.activeExam = payload
  },
  SET_CATEGORY_LIST(state, payload) {
    state.categoryList = payload.filter((item)=> {
      if(state.activeExam.subject_detail_type_id != null) {
        if(state.activeExam.grade_id < 10 && (state.activeExam.subject_type_id == 4 || state.activeExam.subject_type_id == 6)) { 
          return item.grade_list.some(grade => (grade == 7 || grade == 8 || grade == 9)) && (item.subject_type_id == state.activeExam.subject_type_id || item.name == '교과과정 제외')
        } else {
          if(item.grade_list.includes(state.activeExam.grade_id)) {
            if(state.activeExam.subject_type_id == 9) {
              return (item.subject_type_id == state.activeExam.subject_type_id && item.subject_detail_type_id == state.activeExam.subject_detail_type_id) || item.name == '교과과정 제외'
            } else {
              return (item.subject_type_id == state.activeExam.subject_type_id) || item.name == '교과과정 제외'
            }
          }
        }
      } else {
        return (item.subject_type_id == state.activeExam.subject_type_id) || item.name == '교과과정 제외'
      }
    });

    // 고1은 중학색것도 포함되게끔
    var tempList = [];
    if(state.activeExam.test_name != null) {
      if(state.activeExam.test_name.indexOf('3월') != -1 &&
      state.activeExam.grade_id == 10 && 
      (state.activeExam.subject_type_id == 3 || state.activeExam.subject_type_id == 4 || state.activeExam.subject_type_id == 5 || state.activeExam.subject_type_id == 6)) { 
        tempList = payload.filter((item) => {
          return item.grade_list.some(grade => (grade == 7 || grade == 8 || grade == 9 && grade != 10))
          && item.subject_type_id == state.activeExam.subject_type_id
        });
        // console.log(state.categoryList);
        // console.log(tempList);
        if(tempList.length) {
          tempList.push(...state.categoryList);
          state.categoryList = tempList;
        }
      }
    }

    // 고2 3월 탐구 과탐이면 기존유형 + 통합과학(고1), 사탐이면 기존유형 + 통합사회(고1)
    var tempList2 = [];
    if(state.activeExam.test_name != null) {
      if(state.activeExam.test_name.indexOf('3월') != -1 && // 3월
        state.activeExam.grade_id == 11   // 고2
        && state.activeExam.subject_type_id == 9  // 탐구
      ) { 
        tempList2 = payload.filter((item) => {
          return item.grade_list.some(grade => (grade == 10)) // 고1
          && item.subject_type_id == (state.activeExam.subject_detail_type_id >= 10 ? 5 : 4)
        });
        for(var i=0; i<tempList2.length; i++) {
          tempList2[i].name = (state.activeExam.subject_detail_type_id >= 10 ? '통합과학 - ' : '통합사회 - ') + tempList2[i].name;
        }
        // console.log(state.categoryList);
        // console.log(tempList2);
        if(tempList2.length) {
          var newArr = state.categoryList.concat(tempList2);
          state.categoryList = newArr;

          // tempList2.push(...state.categoryList);
          // state.categoryList = tempList2;
        }
      }
    }

    console.log(state.categoryList)

    // 고등 수학의 경우에만 아래처럼 변경됨
    for(var i=0; i<state.categoryList.length; i++) {
      // 중학생 수학 , 중학생 과학(5)
      if(state.categoryList[i].school_type == 2 && (state.categoryList[i].subject_type_id  == 3 || state.categoryList[i].subject_type_id  == 5)) {
        for(var j=0; j<state.categoryList[i].grade_list.length; j++) {
          state.categoryList[i].name = (
            state.categoryList[i].grade_list[j] == 7 ? (j + 1 == state.categoryList[i].grade_list.length ? '중1 - ' : '중1, ') 
            : state.categoryList[i].grade_list[j] == 8 ? (j + 1 == state.categoryList[i].grade_list.length ? '중2 - ' : '중2, ') 
            : state.categoryList[i].grade_list[j] == 9 ? (j + 1 == state.categoryList[i].grade_list.length ? '중3 - ' : '중3, ') 
            : ''
          ) + state.categoryList[i].name;
        }
      }

      if(state.categoryList[i].school_type == 3 && state.categoryList[i].subject_type_id  == 3) {
        state.categoryList[i].name = (
          state.categoryList[i].subject_detail_type_id == 0 ? '수학 - ' 
          : state.categoryList[i].subject_detail_type_id == 1 ? '확률과통계 - '
          : state.categoryList[i].subject_detail_type_id == 2 ? '미적분 - '
          : state.categoryList[i].subject_detail_type_id == 3 ? '기하 - '
          : state.categoryList[i].subject_detail_type_id == 4 ? '수학1 - '
          : state.categoryList[i].subject_detail_type_id == 5 ? '수학2 - '
          : ''
        ) + state.categoryList[i].name;
      }
      if(state.categoryList[i].school_type == 2 && state.categoryList[i].subject_type_id  == 6) {
        state.categoryList[i].name = (
          state.categoryList[i].subject_detail_type_id == 1 ? '역사1 - ' 
          : state.categoryList[i].subject_detail_type_id == 2 ? '역사2 - '
          : ''
        ) + state.categoryList[i].name;
      }
      if(state.categoryList[i].school_type == 2 && state.categoryList[i].subject_type_id  == 4) {
        state.categoryList[i].name = (
          state.categoryList[i].subject_detail_type_id == 1 ? '사회1 - ' 
          : state.categoryList[i].subject_detail_type_id == 2 ? '사회2 - '
          : ''
        ) + state.categoryList[i].name;
      }
      // 고2 3월 탐구
      if(state.activeExam.test_name != null) {
        if(state.activeExam.grade_id == 11  // 고2 
          && state.activeExam.test_name.indexOf('3월') != -1  // 3월
          && state.categoryList[i].school_type == 3 // 고등학교 
          && state.categoryList[i].subject_type_id == 9 // 탐구 
        ) {
          state.categoryList[i].name = `${state.activeExam.subject_name} - ` + state.categoryList[i].name;
        }
      }
    }
    if(state.activeExam.grade_id < 10 && state.activeExam.subject_type_id == 6) {
      state.categoryList.sort((a, b) => {
        // grade_id에 따른 정렬 조건
        if (state.activeExam.grade_id < 9) {
          // grade_id가 9보다 작으면 subject_detail_type_id가 1이 앞으로 오게 정렬(중1, 2는 역사1 유형이 앞으로)
          return a.subject_detail_type_id - b.subject_detail_type_id;
        } else {
          // grade_id가 9 이상이면 subject_detail_type_id가 2가 앞으로 오게 정렬(중3은 역사2 유형이 앞으로)
          return b.subject_detail_type_id - a.subject_detail_type_id;
        }
      });
    }
  },
  DONE_TYPE_EDIT(state, payload) {
    state.doneType = payload
  },
  SET_ACTIVE_YEAR(state, payload) {
    state.activeYear = payload
  },
  SET_ACTIVE_TAB(state, payload) {
    state.activeTab = payload
  },
  SET_CATEGORY_MAIN(state, payload) {
    state.categoryMain = payload
  },
  SET_CATEGORY_SUB(state, payload) {
    state.categorySub = payload
  },
  SET_ACTIVE_GRADE(state, payload) {
    state.activeGrade = payload
  },
  SET_TYPE_DASHBOARD(state, payload) {
    state.typeDashboard = payload
    console.log(state.typeDashboard)
  }
}
