/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  sortedExamName: state => JSON.parse(JSON.stringify(state.subjectList)).sort((a, b) => {
      return a.update_time < b.update_time ? -1 : a.update_time > b.update_time ? 1 : 0
  })
}
