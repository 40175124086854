/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_FILTER_LIST_OPT_SHOW(state, index) {
    state.filterList[index].optShow = !state.filterList[index].optShow
  },
  /// payload: {index: int, index2: int}
  SET_FILTER_LIST_OPT_CHECK(state, payload) {
    state.filterList[payload.index].optList[payload.index2].check = !state.filterList[payload.index].optList[payload.index2].check
  }
}
