<template>
  <div id="app" :class="vueAppClasses" @contextmenu.prevent>
		<router-view @setAppClasses="setAppClasses" />
	</div>
</template>

<script>
import jwt from "@/http/requests/auth/jwt/index.js"
export default {
  data() {
    return {
      vueAppClasses: [],
    }
  },
  methods: {
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleResize(e) {
      if(e.target.innerWidth < 1920) {
        this.$store.commit('SET_INNER_WIDTH', e.target.innerWidth)
      } else {
        this.$store.commit('SET_INNER_WIDTH', ((e.target.innerWidth - 444) / e.target.innerWidth) * 100)
      }
    }
  },
  async created() {
    if(localStorage.getItem('accessToken')) {
      jwt.adminInit().then((resolve)=>{
        this.$store.commit('UPDATE_FIRST_USER', resolve.data.beginning)
        // this.$store.commit('UPDATE_USER_INFO', {
        //   about: "None",
        //   alarm: false,
        //   displayName: "필드유",
        //   email: "admin@filledyou.com",
        //   level: 3,
        //   photoURL:  "user01.png",
        //   providerId: "jwt",
        //   status: "online",
        //   uid: 32,
        //   userRole: 6
        // })
        this.$store.commit('UPDATE_USER_INFO', resolve.data.userData)
      }).catch(() => {
        if(this.$route.path != '/admin/auth/login') {
          this.$router.push('/admin/auth/login')
        }
      })
    } 
    if(localStorage.getItem('filleduToken')) {
      jwt.init().then((resolve)=>{
        this.$store.commit('UPDATE_CUSTOMER_INFO', resolve.data.userData)
      }).catch(() => {
        this.$router.push('/')
        // this.$router.push('/main')
        // this.$router.push('/main').catch(()=>{});
      })
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.$store.commit('SET_INNER_WIDTH', window.innerWidth)
    if (window.innerWidth <= 599) {
      this.$store.commit("HANDLE_IS_MOBILE", true)
    } else {
      this.$store.commit("HANDLE_IS_MOBILE", false)
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize)
  },
}
</script>
<style>
#app {
  width:100%;
  height:100%;
  font-family: pretendard, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  -webkit-overflow-scrolling: hidden;
}
body {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translate(-50%, -5px) !important;
  opacity: 0;
}
.slide-enter-active {
   -moz-transition-duration: 0.2s;
   -webkit-transition-duration: 0.2s;
   -o-transition-duration: 0.2s;
   transition-duration: 0.2s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.2s;
   -webkit-transition-duration: 0.2s;
   -o-transition-duration: 0.2s;
   transition-duration: 0.2s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100%;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>