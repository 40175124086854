/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  allTypeExam: [],
  searchKeyword: {  // filter
    text: '',
    key: 'exam_subject'
  },
  allExamList: [],
  /* Staging_exam 에 쓰이는 category */
  largeCategory: [],
  middleCategory: [],
  smallCategory: [],

  /* Staging_exam 에 쓰이는 content */
  publisher: [],
  textbook_subject: [],
  textbook_content: [],
  textbook_content_main: [],
  textbook_content_sub: [],

  stagingExamList: [],
  stagingExamId: null,
  stagingExamInfo: [],
  stagingQuestionList: [],
  findStagingQuestionList: [],
  mutateSubject: [],
  mutateId: null,
  allowExamList: [],
  userAllowList: [],
  userDoneList: [],
  adminDoneList: [],
  allSchoolList: [],
  filteredSchool: [],
  keyword: '',
  subjectCode: [],
  subjectCodeListAll: [],
  gradeId: null,
  examId: null,
  activeExam: null,
  examSubject: [],
  examSubjectAll: [],
  schoolSubjectAll: [],
  activeSubject: null,
  reviewList: [],
  subjectDetail: null,
  activeFile: null,
  activeHighSchool: null,
  createModal: false,
  createNational: false,
  schoolId: null,
  questionList: [],
  questionMultiple: [],
  problemList: [],
  activeQuestion: null,
  lastQuestion: null,
  activeQuestionIndex: 0,
  activeMultipleList: [],
  multipleList: [],
  fileInfo: null,
  firstUser: false,
  examDone: false,
  targetUser: [],
  activeRegion: '전체', // 경기도
  schoolPublisher: [],
  activeYear: '연도별', // filter
  activeFilterSub: {  // filter (.name, .subject_type_id)
    name: '과목별',
    grade_id: '',
    subject_type_id: 0
  },
  showAllReview: false,
  activeAdmin: [],
  activeCategory: [], // filter
  isProduct: [],
  isCreate: [],
  subjectFile: {},
  activeIndex: 0,
  activeGrade: [],  // filter
  activeSemester: [],
  activeWork: [],
  activeType: [], // filter
  activeClass: [],
  schoolCategory: [],
  otherCategory: [],
  allSampleList: [],
  literatureList: [],
  scrollTop: 0,
  examAllMenu: [
    {
        text: '상태',
        name: 'state',
        rate: 7,
        arrow: 0
    },{
        text: '학년',
        name: 'grade_id',
        rate: 8,
        arrow: 0
    },{
        text: '과목',
        name: 'subject_code_name',
        rate: 8,
        arrow: 0
    },{
        text: '시험 이름',
        name: 'name',
        rate: 20,
        arrow: 0
    },{
        text: '',
        name: '',
        rate: 5,
        arrow: 0
    },{
        text: '작성',
        name: 'user_name',
        rate: 8,
        arrow: 0
    },{
        text: '검토',
        name: 'admin_name',
        rate: 8,
        arrow: 0
    },{
        text: '분류',
        name: 'teacher_name',
        rate: 8,
        arrow: 0
    },{
        text: '입력단가',
        name: 'enter_price',
        rate: 8,
        arrow: 0
    },{
        text: '분류단가',
        name: 'class_price',
        rate: 8,
        arrow: 0
    },{
        text: '최종수정일',
        name: 'update_time',
        rate: 12,
        arrow: 0
    }
  ],
  StagingExamMenu: [
    {
      text: '학년',
      name: 'grade_id',
      rate: 8,
      arrow: 0
    },
    {
      text: '과목',
      name: 'subject_code',
      rate: 8,
      arrow: 0
    },
    {
      text: '시험 이름',
      name: 'name',
      rate: 20,
      arrow: 0
    },
    {
      text: '생성일',
      name: 'create_time',
      rate: 12,
      arrow: 0
    },
    {
      text: '최종수정일',
      name: 'update_time',
      rate: 12,
      arrow: 0
    }
  ],
  // warning bottom modal
  warningModal: false,
  warningQuestionInfo: {before_order_index: null, now_order_index: 0, question_info: {}},
  // notAllDone bottom modal
  notAllDoneModal: false,
  questionDetailInfo: {
    info: null,
    error_list: null
  },
  // ErrorExamModal.vue
  errorExamModal: false,
  // 관리 > 시험관리 = 필터 검색 타입 1: 문제id 검색, 2: 시험 검색
  searchType: 2,  // filter
  // 내작업>문제입력  = 입력완료 버튼(각문항 별 한개 이상 (문항 텍스트 내용 한개 이상 안입력됐을 때))
  errorMultiple: false,

  // 생성>내신>시험생성(내신): 필터 학년 (중,고)
  selectExamGradeId: null,

  // 생성>내신(중,고)>시험 생성 창 움직일 수 있는 판단 값 , x축,y축
  examIsPress: false,
  examPrevPosX: 0,  // 50%
  examPrevPosY: 0,  // 50%
  hideSelected: [],  // fillter 는 아닌데 초기화 필요
  nationalExamList: [], //모의고사 원본리스트
  nationalMutateExamList: [], //모의고사 변형리스트

  
  examInfo: null,
  skipExamEdit: false,
  examAdminCheck: false,
}
