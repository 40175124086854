/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import axios from "../../http/axios/index.js"
import commit from "@/store/consulting/moduleConsultingMutations.js"

export default {
  SET_UNIV_MAIN_CATEGORY_INDEX(state, payload) {
    // 대분류
    state.univCateMainIdx = payload
  },
  SET_UNIV_MIDDLE_CATEGORY_INDEX(state, payload) {
    // 중분류
    state.univCateMiddleIdx = payload
  },
  SET_ALL_UNIV_LIST(state, payload) {
    state.allUnivList = payload
  },
  SET_ALL_MAJOR_LIST(state, payload) {
    state.allMajorList = payload
  },
  SET_ALL_UNIV_MAJOR_LIST(state, payload) {
    state.allUnivMajorList = payload
  },
  SELECT_FILTER_ITEM(state, payload) {
    // type - 1: 지역/대학교, 2: 학과, 3: 군
    if(payload.type == 1 || payload.type == 2) {
      // 지역/대학교, 학과 소분류 필터 상태값 변경
      if(payload.name.includes('전체')) {
        // 전체 필터를 클릭했을 때 - 하위 항목들 해제되고 전체필터만 활성화
        if(payload.type == 1) {
          state.activeFilterList = state.activeFilterList.filter((val) => {
            return val.region != payload.region || (val.region == payload.region && val.name.includes('전체'))
          })
        } else {
          state.activeFilterList = state.activeFilterList.filter((val) => {
            return val.major_sub_id != payload.major_sub_id || (val.major_sub_id == payload.major_sub_id && val.name.includes('전체'))
          })
        }
      } else {
        // 하위 필터를 클릭했을 때 - 전체필터가 활성화 된 상태라면 전체필터 해제
        if(payload.type == 1) {
          state.activeFilterList = state.activeFilterList.filter((val) => {
            return val.region != payload.region || (val.region == payload.region && !val.name.includes('전체'))
          })
        } else {
          state.activeFilterList = state.activeFilterList.filter((val) => {
            return val.major_sub_id != payload.major_sub_id || (val.major_sub_id == payload.major_sub_id && !val.name.includes('전체'))
          })
        }
      }
    }
    // 소분류 필터 추가 및 제거
    var idx = state.activeFilterList.findIndex((val) => {
      return val.name == payload.name
    })
    if(idx != -1) {
      state.activeFilterList.splice(idx, 1);
    } else {
      state.activeFilterList.push(payload);
    }
    console.log(state.activeFilterList);
    commit.FILTER_UNIV_MAJOR_LIST(state, state.activeFilterList);
  },
  FILTER_UNIV_MAJOR_LIST(state, payload) {
    console.log('sldfksl')
    console.log(payload)
    var univList = payload.filter((val) => {
      return val.type == 1
    })
    console.log(univList);
    var majorList = payload.filter((val) => {
      return val.type == 2
    })
    console.log(majorList);
    var typeList = payload.filter((val) => {
      return val.type == 3
    })
    console.log(typeList);
    if(typeList.length == 0 && univList.length == 0 && majorList.length == 0) {
      state.filteredList = []
    } else {
      if(typeList.length > 0) {
        state.filteredList = state.allUnivMajorList.filter((val) => {
          return typeList.some((type) => {
            return type.name == val.type
          })
        })
      } else {
        state.filteredList = state.allUnivMajorList
      }
      console.log(state.filteredList);
      if(univList.length > 0) {
        state.filteredList = state.filteredList.filter((val) => {
          console.log(val);
          return univList.some((univ) => {
            console.log(univ);
            if(univ.name.includes('전체')) {
              return univ.region == val.region
            } else {
              return univ.name == val.univ_name
            }
          })
        })
      }
      console.log(state.filteredList);
      if(majorList.length > 0) {
        console.log(state.filteredList);
        state.filteredList = state.filteredList.filter((val) => {
          return majorList.some((major) => {
            if(major.name.includes('전체')) {
              return major.major_sub_id == val.keyword_id
            } else {
              return major.name == val.major_name
            }
          })
        })
      }
    }
    console.log(state.filteredList);
  },
  SET_UNIV_SEARCH_RESULT(state, payload) {
    // 목표대학 필터 검색결과 리스트
    state.univSearchResult = payload
    console.log(state.univSearchResult); 
  },
  RESET_UNIV_FILTER_SUB(state, payload) {
    // 선택한 소분류 필터 초기화
    if(payload.type == 1) {
      state.activeFilterList = state.activeFilterList.filter((val) => {
        return val.region != payload.content
      })
    } else {
      state.activeFilterList = state.activeFilterList.filter((val) => {
        return val.major_sub_id != payload.content
      })
    }
    commit.FILTER_UNIV_MAJOR_LIST(state, state.activeFilterList);
  },
  RESET_UNIV_FILTER_ALL(state, payload) {
    // 전체 필터 초기화
    state.activeFilterList = []
    commit.FILTER_UNIV_MAJOR_LIST(state, state.activeFilterList);
  },

  SET_SCALED_SCORE_MODAL(state, payload) {
    state.scaledScoreModal = payload;
  },
  SET_SCALED_SCORE_MODAL_INFO(state, payload) {
    state.scaledScoreModalInfo = payload;
  },
  SET_ALL_EXAM_LIST(state, payload) {
    for(var i=0; i<payload.length; i++) {
      if(!state.examTabList.includes(payload[i].test_name)) {
        state.examTabList.push(payload[i].test_name);
      }
    }
    state.allExamList = payload;
  },
  SET_USER_ID(state, payload) {
    state.userId = payload;
  },
  SET_USER_NAME(state, payload) {
    state.userName = payload;
  },
  SET_EXAM_TAB_INDEX(state, payload) {
    state.examTabIndex = payload;
  },
  SET_QUESTION_LIST(state, payload) {
    state.activeExamList[state.activeExamIndex].question_list = payload;
    // state.questionList = payload;
  },
  SET_ACTIVE_EXAM_LIST(state, payload) {
    console.log(payload);
    state.activeExamList = payload;
  },
  SET_ACTIVE_EXAM(state, payload) {
    state.activeExam = payload;
  },
  SET_ACTIVE_EXAM_INDEX(state, payload) {
    state.activeExamIndex = payload;
  },
  CHANGE_MY_UNIV_LIST(state, payload) {
    console.log(payload.type)
    console.log(state.myUniv[payload.type]);
    if(state.myUniv[payload.type].length) {
      var idx = state.myUniv[payload.type].findIndex((val) => {
        return val.major_id == payload.major_id
      })
      if(idx != -1) {
        state.myUniv[payload.type] = state.myUniv[payload.type].filter((val) => {
          return val.major_id != payload.major_id
        })
      } else {
        state.myUniv[payload.type].push(payload);
      }
    } else {
      state.myUniv[payload.type].push(payload);
    }
    console.log(state.myUniv)
  },
  SET_MY_UNIV_DETAIL_MODAL(state, payload) {
    state.myUnivDetailModal = payload;
  },

  SET_USER_SCHOOL(state, payload) {
    state.userSchool = payload;
  },
  SET_USER_CONSULT_CONTENT(state, payload) {
    state.userConsultContent = payload;
  },

  SET_CONSULT_IS_PRESS(state, payload) {
    state.consultIsPress = payload;
  },
  SET_CONSULT_PREV_POS_X(state, payload) {
    state.consultPrevPosX = payload;
  },
  SET_CONSULT_PREV_POS_Y(state, payload) {
    state.consultPrevPosY = payload;
  },

  SET_CONSULT_WATCH_VAR(state) {
    state.consultWatchVar += 1
  },

  SET_AI_CONSULTING_PRODUCT_LIST(state, payload) {
    state.aiConsultingProductList = payload;
  },
}
