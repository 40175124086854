/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import axios from "../../http/axios/index.js"
import state from "../state.js"
import stateType from "@/store/type/moduleTypeState.js"
import stateProduct from "@/store/product/moduleProductState.js"
export default {
  RESET_PNG_MAP(state) {
    state.pngMap = {src: '',};
  },
  SET_PNG_MAP(state, payload) { // payload: {} 
    state.pngMap = payload;
  },
  SET_ALL_PNG_LIST(state, payload) {
    state.pngList = payload;
  },
  RESET_PNG_LIST(state) {
    state.pngList = [{index: 0, src: ''},];
  },
  SET_IDX_PNG_LIST_NAME(state, payload) { // payload: {index: index, name: ''}
    state.pngList[payload.index].name = payload.name;
  },
  SET_IDX_PNG_LIST(state, payload) {  // payload: {index: index, src: blob}
    state.pngList[payload.index].src = payload.src;
  },
  PUSH_PNG_LIST(state, payload) { // payload: {index: index, src: blob}
    state.pngList.push(payload);
  },
  SLICE_PNG_LIST(state, payload) {  // payload: index
    state.pngList.splice(payload, 1);
  },
  SET_PNG_LIST_SELECT_IDX(state, payload) {
    state.pngListSelectIdx = payload
  },
  RESET_PNG_OVERLAP_LIST(state) {
    state.pngOverlapList = [[{src: null}, ],];
  },
  PUSH_PNG_OVERLAP_LIST(state) {
    state.pngOverlapList.push([{src: null}, ],);
    state.pngOverlapListIdx = state.pngOverlapList.length-1;
    state.pngOverlapListInnerIdx = state.pngOverlapList[state.pngOverlapListIdx].length-1;
    state.pngListSelectIdx = -1;
  },
  PUSH_PNG_OVERLAP_LIST_INNER(state) {
    state.pngOverlapList[state.pngOverlapListIdx].push({src: null},);
    state.pngOverlapListInnerIdx = state.pngOverlapList[state.pngOverlapListIdx].length-1;
  },
  SET_PNG_OVERLAP_LIST(state, payload) {
    state.pngOverlapList[payload.index1][payload.index2].src = payload.src;
    state.pngOverlapList[payload.index1][payload.index2].name = payload.name;
  },
  SET_ALL_PNG_OVERLAP_LIST(state, payload) {
    state.pngOverlapList = payload;
  },
  SPLICE_PNG_OVERLAP_LIST(state, payload) { // payload: {index: 0, index2: 0}
    for(var i=0; i<state.pngOverlapList.length; i++) {
      for(var j=0; j<state.pngOverlapList[i].length; j++) {
        if(i == payload.index && j == payload.index2) state.pngOverlapList[i].splice(j, 1);
      }
    }
    for(var i=0; i<state.pngOverlapList.length; i++) {
      if(state.pngOverlapList[i].length == 0) state.pngOverlapList.splice(i, 1);
    }
  },
  SPLICE_PAGE_PNG_OVERLAP_LIST(state, payload) {
    state.pngOverlapList.splice(payload, 1);
  },
  // SET_IDX_PNG_LIST_NAME(state, payload) { // payload: {index: index, name: ''}
  //   state.pngList[payload.index].name = payload.name;
  // },
  SET_PNG_OVERLAP_LIST_IDX(state, payload) {
    state.pngOverlapListIdx = payload
  },
  SET_PNG_OVERLAP_LIST_INNER_IDX(state, payload) {
    state.pngOverlapListInnerIdx = payload
  },
  RESET_ZIP_FILE_LIST(state) {
    state.zipFileList = [];
  },
  PUSH_ZIP_FILE_LIST(state, payload) {
    state.zipFileList.push(payload);
  },
  SET_SELECT_ZIP_FILE(state, payload) {
    state.selectZipFile = payload;
  },
  SET_EDIT_QUESTION(state, payload) {
    state.editQuestion = payload;
  },
  SET_WATCH_AI_TAGING(state, payload) {
    state.watchAiTaing += payload;
  },
  SET_AI_TAGING_STATE(state, payload) {
    state.aiTaingState = payload;
  },
  SET_CUT_IMG_LIST(state, payload) {  // ..cut_type  1: 문제, 2: 지문, 3: 해설,  ..type 0: 해설, 1: 문제, 2: 지문, 3:1번 ...7:5번
    state.cutImgList = payload;
  }
}
