// axios
import axios from 'axios'

const baseURL = "https://pulda.net:7878"   // 운영
// const baseURL = "http://211.236.62.51:7878"   // 개발
// const baseURL = "http://211.236.62.51:5003"   // 자체개발

// const baseURL = "http://116.125.141.82:7770" // 필드유 운영(상품 관리)(운영)
// const baseURL = "http://211.53.129.38:5002"  // 상품관리(개발)

export default axios.create({
  baseURL: baseURL
  // You can add your headers here
})
