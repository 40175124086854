/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  subjectList: state => state.subjectCode.filter((code)=> {
    if(!state.gradeId) {
      return state.subjectCode
    } else {
      return code.grade == state.gradeId
    }
  }),
  searchedSchool: state => state.allSchoolList.filter((school)=> {
    if(!state.keyword) {
      return state.allSchoolList
    } else {
      return school.school_name == state.keyword || school.school_name.includes(state.keyword)
    }
  }),
}
