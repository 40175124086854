/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import axios from "../../http/axios/index.js"
import state from "../state.js"

export default {
  SET_BUG_EXAM(state, payload) {
    state.examList = payload
  },
  SET_ACTIVE_EXAM(state, payload) {
    state.activeExam = payload
  },
  SET_BUG_SUBJECT_LIST(state, payload) {
    // if(payload.length > 1) {
    //   state.bugQuestionList = payload.sort((a, b) => {
    //     return new Date(b.update_time) - new Date(a.update_time) 
    //   })
    // } else {
      state.bugQuestionList = payload
    // }
    // console.log(payload);
  },
  SET_DONE_QUESTION_LIST(state, payload) {
    state.doneList = payload
  },
  SET_QUESTION_LIST(state, payload) {
    state.questionList = payload
  },
  SET_ACTIVE_QUESTION_INDEX(state, payload) {
    state.activeQuestionIndex = payload
  },
  SET_ACTIVE_SELECT(state, payload) {
    state.activeSelect = payload
  },
  SET_ACTIVE_QUESTION(state, payload) {
    state.activeQuestion = payload
  },
  SET_LAST_QUESTION(state, payload) {
    state.lastQuestion = payload
  },
  SET_ACTIVE_TAB(state, payload) {
    state.activeTab = payload
  },
  SET_DONE_QUESTION_STATE(state, payload) {
    Vue.set(state.questionList[state.activeQuestionIndex], 'admin_check', payload)
  }
}
