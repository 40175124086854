/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Day from 'dayjs'

export default {
  subjectList: state => state.subjectCode.filter((code)=> {
    if(!state.gradeId) {
      return state.subjectCode
    } else {
      return code.grade == state.gradeId
    }
  }),
  searchedSchool: state => state.filteredSchool.filter((school)=> {
    if(!state.keyword) {
      return state.filteredSchool
    } else {
      return school.school_name == state.keyword || school.school_name.includes(state.keyword)
    }
  }),
  searchedExam: state => state.allExamList.filter((exam)=> {
    console.log(state.keyword)
    if(!state.keyword) {
      return state.allExamList
    } else {
      return exam.test_name == state.keyword || exam.test_name.includes(state.keyword)
    }
  }),
  filteredNationalExamList: state => state.nationalExamList.filter((exam)=> {
    return (exam.test_name ? ((exam.test_name == state.searchKeyword.text) || exam.test_name.includes(state.searchKeyword.text)) : (state.searchKeyword.text ? false : true)) && 
      (state.activeGrade.length ? state.activeGrade.includes(exam.grade_id) : true) &&
      ((state.activeYear != '연도별') ? (state.activeYear == Day(exam.release_time).format('YYYY')) : true) &&
      ((state.activeFilterSub.name != '과목별') ? (state.activeFilterSub.subject_type_id == exam.subject_type_id) : true)
  }),
  stagingExamId: state => state.exam.stagingExamId,
  subjectCode: state => {
    return state.exam.subjectCode.sort((a, b) => a.subject_type_id - b.subject_type_id);
  },
  stagingQuestionList: state => {
    return state.exam.stagingQuestionList;
  },
}
