import axios from "../../../axios/index.js"

export default {
  login(email, pwd) {
    return new Promise((resolve, reject) => {
      var loginInfo = {"email": email, "password": pwd}    
      axios.post("https://filledyou.com:7770/api/auth/login", loginInfo).then((response)=> {
        if(response.data.result == 'success') {
          axios.defaults.headers.common['authorization'] = 'token=' + response.data.token
          if(localStorage.getItem('accessToken')) {
            localStorage.removeItem('accessToken')
          }
          // localStorage.setItem('accessAdmin' , true)
          localStorage.setItem('filleduToken' , response.data.token)
        }
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  adminLogin(email, pwd) {
    return new Promise((resolve, reject) => {
      var loginInfo = {"email": email, "password": pwd}    
      axios.post("api/auth/login", loginInfo).then((response)=> {
        if(response.data.result == 'success') {
          axios.defaults.headers.common['authorization'] = 'token=' + response.data.token
          if(localStorage.getItem('filleduToken')) {
            localStorage.removeItem('filleduToken')
          }
          console.log(response.data.token)
          localStorage.setItem('accessToken' , response.data.token)
        }
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  registerUser(name, email, pwd) {
    return axios.post("/api/auth/register", {displayName: name, email: email, password: pwd})
  },
  refreshToken() {
    var token =''
    if(localStorage.getItem("accessToken")){
      token = localStorage.getItem("accessToken")
    }
    if(token){
      return axios.post("/api/auth/refresh-token", { accessToken:token})
    }
    return Promise.reject('NOT TOKEN')
  },
  init(){
    return new Promise((resolve, reject) => {
      var token =''
      if(localStorage.getItem("accessToken")){
        token = localStorage.getItem("accessToken")
      } else if (localStorage.getItem("filleduToken")) {
        token = localStorage.getItem("filleduToken")
      }
      if (token){
        axios.defaults.headers.common['authorization'] = 'token=' + token
        axios.post("https://filledyou.com:7770/api/auth/refresh-token" , { accessToken: token }).then((response)=>{
          if(localStorage.getItem("accessToken")){
            localStorage.setItem("accessToken" , response.data.token)
          } else if (localStorage.getItem("filleduToken")) {
            localStorage.setItem("filleduToken" , response.data.token)
          }
          if(response.data.result == 'success'){
              axios.defaults.headers.common['authorization'] = 'token=' + response.data.token
          }
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      } else{
        reject('NOT TOKEN')
      }
    })
  },
  adminInit(){
    return new Promise((resolve, reject) => {
      var token =''
      if(localStorage.getItem("accessToken")){
        token = localStorage.getItem("accessToken")
      } else if (sessionStorage.getItem("accessToken")) {
        token = sessionStorage.getItem("accessToken")
      }
      if (token){
        axios.defaults.headers.common['authorization'] = 'token=' + token
        axios.post("/api/auth/refresh-token" , { accessToken: token }).then((response)=>{
          localStorage.setItem("accessToken" , response.data.token)
          if(response.data.result == 'success'){
              axios.defaults.headers.common['authorization'] = 'token=' + response.data.token
          }
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      } else{
        reject('NOT TOKEN')
      }
    })
  },
  check() {
    return new Promise((resolve, reject) => {
      this.refreshToken().then(response => {
        // console.log('initTest ' + response.data.token)
        axios.defaults.headers.common['authorization'] = 'token=' + response.data.token
        resolve(response)
      }).catch(error => { reject(error) })
    })
  }
}
