/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  reviewList:[],
  adminList: [],
  activeExam: null,
  examList:[],
  questionList:[],
  activeExamItem:'',
  activeSubjectId : '',
  activeQuestion: null,
  lastQuestion: null,
  activeIndex: 0,
  preQuestionIndex: 0,
  activeMultipleList: [],
  activeReview: [],
  activeQuestionItem: [],
  count_review: 0,
  activeCommentTag: [],
  activeComment: [],
  doneReviewEdit: [],
  returnAll: false,
  examDone: false,
  addExample: false,
  userComment: [],
  sampleList: [],
  alarmList: [],
}
