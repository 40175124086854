/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  searchedBook: state => state.allBookList.filter((book)=> {
    if(!state.keyword) {
      return state.allBookList
    } else {
      return book.textbook_name == state.keyword || book.textbook_name.includes(state.keyword)
    }
  })
}
