/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  filterList: [
    {
      text: '학년 구분', 
      optShow: false, 
      optList: [
        {text: '중등', check: true, school_type: 2,}, 
        {text: '고등', check: true, school_type: 3,},
      ]
    },{
      text: '학년', 
      optShow: false, 
      optList: [
        {text: '1학년', check: true,}, 
        {text: '2학년', check: true,}, 
        {text: '3학년', check: true,}
      ]
    },{
      text: '과목', 
      optShow: false, 
      optList: [
        {text: '국어', check: true, subject_type_id: 1,}, 
        {text: '영어', check: true, subject_type_id: 2,},
        {text: '수학', check: true, subject_type_id: 3,}, 
        {text: '사회', check: true, subject_type_id: 4,}, 
        {text: '과학', check: true, subject_type_id: 5,}, 
        {text: '한국사/역사', check: true, subject_type_id: 6,},
      ]
    },{
      text: '학기', 
      optShow: false, 
      optList: [
        {text: '1학기 중간', check: true, semester: 1,}, 
        {text: '1학기 기말', check: true, semester: 2,}, 
        {text: '2학기 중간', check: true, semester: 3,}, 
        {text: '2학기 기말', check: true, semester: 4,},
      ]
    },{
      text: '상태', 
      optShow: false, 
      optList: [
        {text: '검수 요청', check: true, state: 1,}, 
        {text: '승인 완료', check: true, state: 3,}, 
        {text: '반려', check: true, state: 2,},
        // {text: '보류', check: true, state: 0,},
      ]
    },
  ],
}
    