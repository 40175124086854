/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import axios from "../../http/axios/index.js"

export default {
  SET_PUBLISHER_LIST(state, payload) {
    state.publishList = payload
  },
  SET_PUBLISH_SUBJECT_LIST(state, payload) {
    state.publishSubject = payload
  },
  SET_ALL_TEXTBOOK_LIST(state, payload) {
    var filter = []
    for(var i in state.publishList) {
      filter = payload.filter((item)=> {
        return item.publisher_id == state.publishList[i].id
      })
      Vue.set(state.publishList[i] , 'book_list' , filter)
      Vue.set(state.publishList[i] , 'arrow' , false)
    }
    state.allBookList = payload
  },
  SEARCH_BOOK(state, payload) {
    state.keyword = payload
  },
  SET_TEXTBOOK_SUBJECT(state, payload) {
    var list = []
    if(!state.filterKeyword) {
      list = payload
    } else {
        if(!state.filterKeyword.grade_id && !state.filterKeyword.subject_type_id) {
          list = payload
        } else {
          if(!state.filterKeyword.subject_type_id) {
            list = payload.filter((item) => {
              return item.grade_id == state.filterKeyword.grade_id
            })
          } else if(!state.filterKeyword.grade_id) {
            list = payload.filter((item) => {
              return item.subject_type_id == state.filterKeyword.subject_type_id
            })
          } else {
            list = payload.filter((item) => {
              return item.subject_type_id == state.filterKeyword.subject_type_id && item.grade_id == state.filterKeyword.grade_id
            })
          }
        }
    }
    for(var i in list) {
      Vue.set(list[i] , 'positionY' , (i* 60) + 10)
    }
    state.bookSubject = list
  },
  FILETER_BOOK_LIST(state, payload) {
    state.filterKeyword = payload
  },
  SET_BOOKS_MAIN_TABLE(state, payload) {
    state.mainTable = payload.sort((a, b)=> {
      return a.index - b.index
    })
  },
  SET_BOOKS_MIDDLE_TABLE(state, payload) {
    for(var i in state.mainTable) {
      var filter = payload.filter((item) => {
        return item.content_id == state.mainTable[i].id
      })
      Vue.set(state.mainTable[i] , 'middleTable' , filter)
    }
  },
  SET_BOOKS_SUB_TABLE(state, payload) {
    for(var i in state.mainTable) {
      for(var j in state.mainTable[i].middleTable) {
        var filter = payload.filter((item) => {
          return item.content_main_id == state.mainTable[i].middleTable[j].id
        })
        Vue.set(state.mainTable[i].middleTable[j] , 'subTable' , filter)
      }
    }
    console.log(state.mainTable)
  },
  SET_TABLE_SUBJECT(state, payload) {
    state.tableSubject = payload
  },
  SET_MUTATE_SUBJECT(state, payload) {
    state.mutateSubject = payload
  },
  SET_TABLE_SUBJECT_ID(state, payload) {
    state.subjectId = payload
  },
  SET_ACTIVE_BOOK_SUBJECT(state, payload) {
    state.subjectDetail = payload
  }
}
