/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  getPublisherList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/publisher")
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_PUBLISHER_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getPublisherSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload)
      axios.get("/api/v2/exam/school_subject/textbook_subject?publisher_id=" + payload.publisher_id + "&subject_type_id=" + payload.subject_type_id + "&subject_detail_type_id=" + payload.subject_detail_type_id + "&school_type=" + payload.school_type + "&grade_id=" + payload.grade_id + "&detail=" + payload.detail)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_PUBLISH_SUBJECT_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getTextbookList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/publisher_textbook")
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_ALL_TEXTBOOK_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleTextbookList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_publisher_textbook")
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_ALL_TEXTBOOK_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getTextbookSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_subject?textbook_id=" + payload.id + "&year=" + payload.year)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_TEXTBOOK_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleTextbookSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_textbook_subject?textbook_id=" + payload.id + "&year=" + payload.year)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_TEXTBOOK_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getContentSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_content_subject?subject_id=" + payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            console.log(response)
            commit("SET_BOOKS_MAIN_TABLE", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleContentSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_textbook_content_subject?subject_id=" + payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            console.log(response)
            commit("SET_BOOKS_MAIN_TABLE", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createMainTable(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/textbook_content" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createMiddleMainTable(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/textbook_content" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getBooksMiddleTable({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_content_main_subject?subject_id=" + payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_BOOKS_MIDDLE_TABLE", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleBooksMiddleTable({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_textbook_content_main_subject?subject_id=" + payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_BOOKS_MIDDLE_TABLE", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createCenterTable(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/textbook_content_main" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createMiddleCenterTable(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/middle_textbook_content_main" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getBooksSubTable({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_content_sub_subject?subject_id=" + payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_BOOKS_SUB_TABLE", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleBooksSubTable({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_textbook_content_sub_subject?subject_id=" + payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_BOOKS_SUB_TABLE", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createSubTable(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/textbook_content_sub" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createMiddleSubTable(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/middle_textbook_content_sub" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteMainTable(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/textbook_content/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteMiddleTable(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/textbook_content_main/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteSubTable(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/textbook_content_sub/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getExamContent({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_exam_content?content_id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_TABLE_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getExamContentMain({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_exam_content?content_id=" + payload.main + "&content_main_id=" + payload.middle)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit("SET_TABLE_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getExamContentSub({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_exam_content?content_id=" + payload.main + "&content_main_id=" + payload.middle + "&content_sub_id=" + payload.sub)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit("SET_TABLE_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleExamContent({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_textbook_exam_content?content_id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_TABLE_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleExamContentMain({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_textbook_exam_content?content_id=" + payload.main + "&content_main_id=" + payload.middle)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit("SET_TABLE_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleExamContentSub({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_textbook_exam_content?content_id=" + payload.main + "&content_main_id=" + payload.middle + "&content_sub_id=" + payload.sub)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit("SET_TABLE_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMainMutateSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_exam_content_trans?content_id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_MUTATE_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleMutateSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_textbook_exam_content_trans?content_id=" + payload.main + "&content_main_id=" + payload.middle)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit("SET_MUTATE_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSubMutateSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_exam_content_trans?content_id=" + payload.main + "&content_main_id=" + payload.middle + "&content_sub_id=" + payload.sub)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit("SET_MUTATE_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createTableSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/textbook_exam" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_TABLE_SUBJECT_ID' , response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createMiddleTableSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/middle_textbook_exam" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_TABLE_SUBJECT_ID' , response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editTableSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/textbook_exam" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteBookSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/textbook_exam/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getBookExamInfo({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/all_subject_exam?id=" + payload.id + '&type=' + payload.type)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_ACTIVE_BOOK_SUBJECT' , response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleBookExamInfo({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_textbook_exam?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_ACTIVE_BOOK_SUBJECT' , response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editMainTitle(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/textbook_content" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editMiddleTitle(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/textbook_content_main" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editSubTitle(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/textbook_content_sub" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
}
