/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  // 오류 검토할 문제리스트
  getBugExam({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v2/exam/bug_exam')
      .then((response)=> {
        if(response.data.result == 'success') {
          commit('SET_BUG_EXAM', response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error)=> {
        reject(error)
      })
    })
  },
  // 오류 검토중 시험리스트
  getBugSubject({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v2/exam/bug_exam_question')
      .then((response)=> {
        if(response.data.result == 'success') {
          console.log(response.data.data_list)
          commit('SET_BUG_SUBJECT_LIST', response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error)=> {
        reject(error)
      })
    })
  },
  // 오류 검토완료 문제리스트
  getDoneQuestion({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v2/exam/bug_exam_question_done')
      .then((response)=> {
        if(response.data.result == 'success') {
          commit('SET_DONE_QUESTION_LIST', response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error)=> {
        reject(error)
      })
    })
  },
  getQuestionList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v2/exam/bug/question_list?id=' + payload.id + '&type=' + payload.type)
      .then((response)=> {
        console.log(response)
        if(response.data.result == 'success') {
          commit('SET_QUESTION_LIST', response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error)=> {
        reject(error)
      })
    })
  },
  getExamDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/all_subject_exam?id=" + payload.id + '&type=' + payload.type)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_EXAM", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  startReview(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/v2/exam/question_bug_1', payload)
      .then((response)=> {
        if(response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error)=> {
        reject(error)
      })
    })
  },
  getQuestionItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/bug/question_id?id=" + payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_QUESTION", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getLastQuestionInfo({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/bug/question_id?id=" + payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_LAST_QUESTION", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  doneQuestionReview(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/bug/question" , payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  submitDoneList(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/bug/question_done" , payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editQuestion(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/bug/question", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response.data.data)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editExamMultiple(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/bug/question_multiple", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response.data.data)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
}
