/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  getExamSubject({
    commit
  }){
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/review_exam")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_REVIEW_SUBJECT_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAdminList({
    commit
  }){
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/admin_list")
        .then((response) => {
          if (response.data.result == 'success') {
            // console.log(response.data.data)
            commit('SET_ADMIN_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getExamDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/all_subject_exam?id=" + payload.id + '&type=' + payload.type)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_EXAM", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_subject?id=" + payload.id + "&type=" + payload.type)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response.data)
            commit('SET_ACTIVE_EXAM', response.data.data.subject_info)
            commit('COUNT_REVIEW', response.data.data.count)
            commit('SET_QUESTION_LIST', response.data.data.question_list)
            // commit('GET_DONE_REVIEW_EDIT', response.data.data_list)
            // commit('SET_ACTIVE_QUESTION', response.data.data_list[0])
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response.data.data)
            commit('SET_ACTIVE_QUESTION', response.data.data)
            commit('SET_ACITVE_COMMENT_TAG', response.data.data)
            commit('SET_ACITVE_COMMENT', response.data.data)

            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserCommnetList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_comment_user")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_USER_COMMENT_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendExampleQuestion(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/question_sample", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeExampleQuestion(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_sample", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionSample() {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_sample")
        .then((response) => {
          if (response.data.result == 'success') {
            // console.log(response.data.data)
            commit('SET_QUESTION_SAMPLE_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteSample(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/question_sample/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getLastQuestionInfo({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            // console.log(response.data.data)
            commit('SET_PRE_QUESTION', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveEditInfo(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createExamMultiple({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/question_multiple", payload) // 1->5개로 바껴서 버그 날수도있는데 수정 필요한 것 같음
        .then((response) => {
          if (response.data.result == 'success') {
            // console.log(response.data)
            commit("CREATE_MULTIPLE", response.data.data)
            resolve(response.data.data)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editExamMultiple({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_multiple", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('EDIT_MULTIPLE', response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteMultiple({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/question_multiple/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("DELETE_MULTIPLE", payload)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveReview({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_return", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SAVE_REVIEW', response.data.data)
            commit('COUNT_REVIEW', 1)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createCommentReview({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/question_comment", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('COUNT_REVIEW', 1)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editCommentReview(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_comment", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteCommentReview(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/question_comment/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createQuestion({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/question/add", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("CREATE_QUESTION", response.data.data)
            resolve(response.data.data)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteQuestion(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/question/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeState({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/review_exam?type=" + payload.type + "&id=" + payload.id + "&admin_check=" + payload.admin_check +"&state=" + payload.state + "&mark=" + payload.mark + "&exam_name=" + payload.exam_name + "&user_id=" + payload.user_id)
        .then((response) => {
          if (response.data.result == 'success') {
            // commit('GET_SUBJECT_LIST', response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  examDone({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/review_exam_done?type=" + payload.type + "&id=" + payload.id)
        .then((response) => {
          if (response.data.result == 'success') {
            // commit('GET_SUBJECT_LIST', response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  setQuestionCopyItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_head" , {
        "head_question_id" : payload.head_question_id,
        "question_id": payload.question_id
      })
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_HEAD_QUESTION_ID', payload.index)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteQuestionCopyItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_head_return" , {
        question_id: payload.question_id
      })
        .then((response) => {
          if (response.data.result == 'success') {
            commit('DELETE_HEAD_QUESTION_ID', payload.index)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  }
}
