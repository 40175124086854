/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  /// payload: {school_type: [], grade_id: [], subject_type_id: [], semester: [], state: []}
  getTestPaperList({ }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/user_upload_file_list?school_type=" + payload.school_type + '&grade_id=' + payload.grade_id + '&subject_type_id=' + payload.subject_type_id + '&semester=' + payload.semester +'&state=' + payload.state).then((response) => {
        if (response.data.result == 'success') {
          // commit("SET_ACTIVE_EXAM", response.data.data)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getTestPage({ }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/user_upload_file?id=" + payload).then((response) => {
        resolve(response)
      });
    })
  },
  // {
  //    "id": 10,
  //    "state": 2,
  //    "comment": "적절하지 않는 시험입니다"
  // }
  setStateTestPaper(context, payload) { 
    return new Promise((resolve, reject) => {
      axios.put('/api/v2/common/user_upload_file', payload).then((response)=> {
        if(response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error)=> {
        reject(error)
      })
    })
  },
  // {id: 10, file_name: 'g_che1_hsj_ANP2A368.pdf', type: 2}
  getTestPageFileSize({ }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v2/common/file_size?id=${payload.id}&file_name=${payload.file_name}&type=${payload.type}`).then((response) => {
        resolve({
          'response': response,
          'file_name': payload.file_name,
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // pdf 파일 다운로드 , payload: {'id': 550, 'type': 2, 'file_name': '24년 한국사 답안지.pdf'}
  downPdf({ }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v2/common/user_upload_file/download', payload, { responseType: 'blob' }).then((response) => {
        console.log(response);

        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', payload.file_name);
        document.body.appendChild(link);
        link.click();

        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  changeSubject(context, payload) { 
    return new Promise((resolve, reject) => {
      axios.put('/api/v2/common/user_upload_file/subject_type', payload).then((response)=> {
          resolve(response)
      });
    })
  },
}
