/*=========================================================================================
  File Name: moduleUserManagement.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleBookState.js'
import mutations from './moduleBookMutations.js'
import actions from './moduleBookActions.js'
import getters from './moduleBookGetters.js'

export default {
		namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
