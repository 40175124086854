/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js"

export default {
  SET_PRODUCT_LIST(state, payload) {
    state.productList = payload
  },
  SET_HIDDEN_PRODUCT_LIST(state, payload) {
    state.hiddenList = payload;
  },
  OPEN_MENU_EDIT_MODAL(state, payload) {
    state.isMenuEdit = payload
  },
  OPEN_CREATE_PRODUCT(state, payload) {
    state.createProduct = payload
  },
  SET_ACTIVE_PRODUCT(state, payload) {
    state.activeProduct = payload
  },
  SET_ACTIVE_IMAGE_FILE(state, payload) {
    state.activeImg = payload
  },
  SET_PRODUCT_CATEGORY(state, payload) {
    state.productCategory = payload
  },
  SET_PRODUCT_ETC_CATEGORY_LIST(state, payload) {
    state.productEtcCategoryList = payload;
  },
  SET_HIDE_MODAL(state, payload) {
    state.hideModal = payload;
  },
  SET_HIDE_DELETE_MODAL(state, payload) {
    state.hideDeleteModal = payload;
  },
  SET_PRODUCT_SEARCH(state, payload) {
    state.productSearch = payload;
  },
  SET_PRODUCT_HIDE_SEARCH(state, payload) {
    state.productHideSearch = payload;
  }
}
