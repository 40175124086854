/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  // sortUnivList: state => {
  //   for(var i in state.allUnivList) {
  //     state.allUnivList[i].array_agg.slice().sort((a, b) => {
  //       return a.name.localeCompare(b.name);
  //     })
  //   }
  // },
  // sortMajorList: state => state.allMajorList.slice().sort((a, b) => {
  //   return a.name.localeCompare(b.name);
  // }),
}
