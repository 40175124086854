// axios
// 결제페이지에서 헤더 토큰 안붙이고 유저 상태값 호출할 때 사용함
import axios_filledyou from 'axios'

const baseURL = "https://filledyou.com:7770"   // 운영
// const baseURL = "http://211.236.62.51:7771"   // 개발

// const baseURL = "http://116.125.141.82:7770" // 필드유 운영(상품 관리)(운영)
// const baseURL = "http://211.53.129.38:5002"  // 상품관리(개발)

export default axios_filledyou.create({
  baseURL: baseURL
  // You can add your headers here
})
