/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  getProductList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.get(`${baseURL}/api/v2/store/homepage/product_list`).then((response) => {
        if (response.data.result == 'success'){
          commit('SET_PRODUCT_LIST', response.data.data);

          // 기타 카테고리 id 세팅 (이거는 변동되서 고정값이 아니여서 가변적으로 세팅)
          var tempEtcCategorylist = [];
          for(var i=0; i<response.data.data.length; i++) {
            var seq = response.data.data[i].category1_seq 
            if(seq != '1'&&seq != '2'&&seq != '3'&&seq != '4'&&seq != '5'
              &&seq != '6'&&seq != '7'&&seq != '12'&&seq != '8'&&seq != '19'
              &&seq != '9'&&seq != '11'&&seq != '10'&&seq != '13'&&seq != '14'
            ) {
              tempEtcCategorylist.push(Number(seq));
            }
          }
          const set = new Set(tempEtcCategorylist);
          const uniqueArr = [...set];
          // console.log(uniqueArr);
          commit('SET_PRODUCT_ETC_CATEGORY_LIST', uniqueArr);

          resolve(response.data.data_list)
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getHiddneProductList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.get(`${baseURL}/api/v2/store/homepage/product_list/hidden`).then((response) => {
        if (response.data.result == 'success'){
          commit('SET_HIDDEN_PRODUCT_LIST', response.data.data)
          resolve(response.data.data_list)
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getProductInfo({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.get(`${baseURL}/api/v2/store/homepage/product?goods_code=` + payload).then((response) => {
        if (response.data.result == 'success'){
          commit('SET_ACTIVE_PRODUCT', response.data.data[0])
          resolve(response.data.data[0])
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getProductCategory({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.get(`${baseURL}/api/v2/store/homepage/product/type_list`, payload).then((response) => {
        if (response.data.result == 'success'){
          // var list = [];
          // var check_category_list = [
          //   {
          //     'text': '카페·디저트',
          //     'category_seq': [1, 2, 3],
          //   },{
          //     'text': '편의점',
          //     'category_seq': [4],
          //   },{
          //     'text': '패스트푸드',
          //     'category_seq': [5],
          //   },{
          //     'text': '분식·배달',
          //     'category_seq': [6],
          //   },{
          //     'text': '문화생활',
          //     'category_seq': [7, 12],
          //   },{
          //     'text': '상품권',
          //     'category_seq': [8, 19],
          //   },{
          //     'text': '뷰티·생활',
          //     'category_seq': [9, 11],
          //   },{
          //     'text': '가전·디지털',
          //     'category_seq': [10, 13, 14],
          //   },
          // ]
          // for(var i=0; i<response.data.data_list.length; i++) {
          //   for(var j=0; j<check_category_list.length; j++) {
          //     for(var l=0; l<check_category_list[j].category_seq.length; l++) {
          //       if(response.data.data_list[i].category1_seq == check_category_list[j].category_seq[l]) {
          //         list.push({
          //           goods_type: check_category_list[j].text,
          //           category1_seq: response.data.data_list[i].category1_seq,
          //         });
          //         if(1<list.length) {
          //           for(var k=0; k<list.length-1; k++) {
          //             if(list[k].goods_type == list[list.length-1].goods_type) {
          //               list.pop(); // 마지막 배열 삭제
          //             }
          //           }
          //         }
          //         // console.log(i)
          //       }
          //     }
          //   }
          // }
          // console.log(list)
          // for(var i=0; i<list.length; i++) {
          //   // if(list[i].)
          // }
          // console.log(response.data.data_list)
          // commit('SET_PRODUCT_CATEGORY', list)
          // 위 주석처리 해놓은 부분은 카테고리 앱단처럼 맞춘 부분임

          // null 처리
          var list = [];
          for(var i=0; i<response.data.data_list.length; i++) {
            if(response.data.data_list[i].goods_type != null || response.data.data_list[i].category1_seq != null) list.push(response.data.data_list[i]);
          }
          commit('SET_PRODUCT_CATEGORY', list)
          // commit('SET_PRODUCT_CATEGORY', response.data.data_list)
          resolve(response.data.data_list)
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createProduct(context, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.post(`${baseURL}/api/v2/store/homepage/product/create`, payload).then((response) => {
        if (response.data.result == 'success'){
          resolve(response.data.data)
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  editProduct(context, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.put(`${baseURL}/api/v2/store/homepage/product/update`, payload).then((response) => {
        if (response.data.result == 'success'){
          resolve(response.data.data)
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  uploadImg({
    commit
  }, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("type",  payload.type)
    frm.append("file",  payload.file)

    if(payload.goods_code != null) frm.append("goods_code",  payload.goods_code)
    
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.put(`${baseURL}/api/v2/store/homepage/product/image_file` , frm, {headers:{'Content-Type': 'multipart/form-data'}}).then((response) => {
        if (response.data.result == 'success') {
          console.log(response)
          commit('SET_ACTIVE_IMAGE_FILE', {
            'file_name': response.data.file_name,
            'file_path': response.data.file_path,
            'goods_code': response.data.goods_code,
          })
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  deleteGoods(context, payload) {
    return new Promise((resolve, reject) => { 
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.delete(`${baseURL}/api/v2/store/homepage/product?goods_code=` + payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  // 상품리스트 숨김목록 복구
  restoreHideList(context, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.put(`${baseURL}/api/v2/store/homepage/product_list/hidden`, payload).then((response) => {
        if (response.data.result == 'success'){
          resolve(response.data.data)
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  productImgDownload(context, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:5002" : "https://filledyou.com:7770";
      axios.post(`${baseURL}/api/v2/store/homepage/file/download`, payload, {responseType: 'blob'}).then((response) => {
        resolve(response)
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a')
        link.href = url;
        link.setAttribute('download', payload.file_path);
        document.body.appendChild(link);
        link.click();

        resolve(response)
        // reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
}
