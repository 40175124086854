/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js"
import axios_filledyou from "@/axios_custom.js"

const actions = {
  uploadFile(context, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("type",  payload.type)
    frm.append("file",  payload.file)

    // 자르기 속성
    frm.append("is_divide",  payload.isDivide)
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/exam_subject_file" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadBookFile(context, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("type",  payload.type)
    frm.append("file",  payload.file)
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/textbook_exam_file" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadAudioFile({
    commit
  }, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("file",  payload.file)
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/english_file" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_AUDIO_FILE', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadSchoolFile(context, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("type",  payload.type)
    frm.append("file",  payload.file)
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/school_subject_file" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadSchoolFileMulti(context, payload) {
    var frm = new FormData()
    console.log(payload)
    frm.append("id",  payload.id)
    frm.append("type",  payload.type)
    for(let i in payload.file) {
      frm.append("file",  payload.file[i])
    }
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/school_subject_file" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFile({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject_file" + payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit('SET_ACTIVE_FILE', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  pageLog(context, payload) {
        return new Promise((resolve, reject) => {
      axios_filledyou.post("/api/v2/common/user/page", payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  uploadPngFile(context, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("type",  payload.type)
    frm.append("id_type",  payload.id_type) // 1: subject_file의 id로 호출할 때, 2: exam_subject의 id로 호출할 때
    
    if(payload.type==1) {  // 단일 파일
      frm.append("file",  payload.file)
    }else { // 복수 파일 (type: 2)
      frm.append("file",  payload.file)
      // console.log(payload.id);
      // console.log(payload.type);
      // console.log(payload.id_type);
    }
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/exam_subject_file/png_file_list" , frm, {headers:{'Content-Type': 'multipart/form-data'}}).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }else {
          console.log(frm);
          console.log(response);
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  deletePngFile(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v2/exam/exam_subject_file/png_file_list?id=${payload.id}&type=${payload.type}&file_name=${payload.file_name}&id_type=${payload.id_type}`, {headers:{'Content-Type': 'multipart/form-data'}}).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  // 유입경로 확인 
  referrerLog(context, payload) {  // {referer: 'https://...', user_agent: ''}
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/common/referer_log", payload).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
}

export default actions
