/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  publishList: [],
  publishSubject: [],
  allBookList: [],
  bookSubject: [],
  keyword: null,
  filterKeyword: null,
  booksTable: [],
  mainTable: [],
  tableSubject: [],
  mutateSubject: [],
  subjectId: null,
  subjectDetail: null
}
