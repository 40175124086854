/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  productList: [],
  hiddenList: [],
  isMenuEdit: false,
  createProduct: false,
  activeImg: null,
  activeProduct: null,
  productCategory: [],
  
  productEtcCategoryList: [],
  hideModal: false,
  hideDeleteModal: false,
  productSearch: '',  // 상품 관리 목록
  productHideSearch: '',  // 상품 숨김 목록
}
