/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"
import state from "../state.js"

export default {
  getAllTypeExam({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_all")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ALL_TYPE_EXAM_LIST", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  getStagingExam({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v2/exam/staging_exam`, { params: { id } })
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_STAGING_EXAM_INFO", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  getStagingExamList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/staging_exam_list")
        .then((response) => {
          console.log('API 응답:', response.data);
          if (response.data.result == 'success') {
            commit("SET_STAGING_EXAM_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  deleteStagingExam(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/staging_exam/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  createStagingExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/staging_exam", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_STAGING_EXAM_ID", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  saveStagingExam(context, payload) {
    console.log(payload)
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/staging_exam", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  getFindStagingQuestionList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      const queryString = Object.keys(payload)
        .filter(key => payload[key] !== null && payload[key] !== undefined)
        .map(key => {
          if (Array.isArray(payload[key])) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(payload[key]))}`;
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`;
        })
        .join('&');

      axios.get(`/api/v2/exam/staging_exam/question_select_list?${queryString}`)
        .then((response) => {
          if (response.data.result === 'success') {
            commit('SET_FIND_STAGING_QUESTION_LIST', response.data.data_list);
            resolve(response);
          } else {
            reject(new Error('검색 실패: ' + (response.data.message || '알 수 없는 오류')));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createStagingExam(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/staging_exam", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response.data.data)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  addQuestionToStagingExam(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/staging_question", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  getStagingQuestionList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/staging_exam/question_list?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_STAGING_QUESTION_LIST", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  deleteStagingQuestion(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/staging_question/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  changeStagingQuestionSequence(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/staging_question/sequence", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  getLargeCategories({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/category2?subject_type_id=" + payload.subject_type_id + "&subject_detail_type_id=" + payload.subject_detail_type_id + "&grade_id=" + payload.grade_id)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_LARGE_CATEGORY", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  getMiddleCategories({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/main-category?id=" + payload.id)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_MIDDLE_CATEGORY", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  getSmallCategories({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/sub-category?id=" + payload.id)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SMALL_CATEGORY", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  getPublishers({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/publisher")
        .then((response) => {
          console.log('API 응답:', response.data);
          if (response.data.result == 'success') {
            commit("SET_PUBLISHER_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getBooks({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_subject_publisher?publisher_id=" + payload.publisher_id + "&subject_type_id=" + payload.subject_type_id + "&subject_detail_type_id=" + payload.subject_detail_type_id + "&grade_id=" + payload.grade_id)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_TEXTBOOK_SUBJECT_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getChapters({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_content_subject?subject_id=" + payload.id)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_TEXTBOOK_CONTENT_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleChapters({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_content_main_list?content_id=" + payload.content_id)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_TEXTBOOK_CONTENT_MAIN_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSmallChapters({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/textbook_content_sub_list?content_main_id=" + payload.content_main_id)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_TEXTBOOK_CONTENT_SUB_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  getAllowExam({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/enter_exam")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ALLOWED_EXAM_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getExamDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/all_subject_exam?id=" + payload.id + '&type=' + payload.type)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_EXAM_INFO", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMutateDoneExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/modify/exam_subject_done")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_MUTATE_DONE_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMutateSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/modify/exam_subject/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_MUTATE_EXAM_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createMutationExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/modify/exam_subject", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_MUTATE_EXAM_ID", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMutationExamDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_MUTATE_EXAM_DETAIL", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getUserAllowExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("api/v2/exam/enter_exam_user?user_id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_USER_ALLOWED_EXAM_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  saveQuestion(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteQuestion(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/question/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  changeState(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/enter_exam", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  changeStateDone(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/enter_exam_done", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_subject?id=" + payload.id + "&type=" + payload.type)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_EXAM_INFO", response.data.data.subject_info)
            commit("SET_QUESTION_LIST", response.data.data.question_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_QUESTION", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSchoolPublisher({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/school_subject/publisher?school_type=" + payload.school_type + '&subject_type_id=' + payload.subject_type_id + '&subject_detail_type_id=' + payload.subject_detail_type_id + '&grade_id=' + payload.grade_id + '&detail=' + payload.detail)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SCHOOL_PUBLISHER", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSchoolSubjectCategory({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      var params = '&'
      for (var key of Object.keys(payload)) {
        if (payload[key]) {
          params += key + '=' + payload[key] + (key != Object.keys(payload)[Object.keys(payload).length - 1] ? '&' : '')
        }
      }
      // if(payload.subject_detail_type_id == 0) {
      //   params = "subject_type_id=" + payload.subject_type_id + (payload.textbook_subject_id ? ('&textbook_subject_id=' + payload.textbook_subject_id) : '') + '&grade_id=' + payload.grade_id;
      // } else {
      //   params = "subject_type_id=" + payload.subject_type_id + '&textbook_subject_id=' + payload.textbook_subject_id + '&subject_detail_type_id=' + payload.subject_detail_type_id + '&grade_id=' + payload.grade_id;
      // }
      axios.get('/api/v2/exam/school_subject/content?' + params)
        // axios.get("/api/v2/exam/school_subject/content?subject_type_id=" + payload.subject_type_id + '&textbook_subject_id=' + payload.textbook_subject_id)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_SCHOOL_CATEGORY", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getOtherCategoryList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/other_category?subject_type_id=" + payload.subject_type_id + '&subject_detail_type_id=' + payload.subject_detail_type_id + '&school_type=' + payload.school_type)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_OTHER_CATEGORY_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionSample({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_sample/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_QUESTION", response.data.question)
            commit("SET_ACTIVE_EXAM_INFO", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getLastQuestionInfo({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_LAST_QUESTION", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createQuestion(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/question/add", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response.data.data)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionMultiple({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_multiple/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response.data.data)
            commit('SET_QUESTION_MULTIPLE', response.data.data_list)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  saveEditInfo(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createExamMultiple({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/question_multiple", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("CREATE_MULTIPLE", response.data.data)
            resolve(response.data.data)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editExamMultiple(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_multiple", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response.data.data)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // 개별 처리 됐었을 때(서버)
  deleteMultiple({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/question_multiple/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("DELETE_MULTIPLE", payload)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 일괄 처리로 수정됐을 때
  newDeleteMultiple({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/question_multiple/" + payload).then((response) => {
        if (response.data.result == 'success') resolve(response)
        reject(response)
      })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleSchoolList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/middle_school_info")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ALL_SCHOOL_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getHighSchoolList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/high_school_info")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ALL_SCHOOL_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/exam", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_EXAM", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/exam", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_EXAM", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createExamSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/exam_subject", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editSchoolSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/school_subject", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteSchoolSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/school_subject/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  editExamSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/exam_subject", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  sendReview(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/enter_exam?type=" + payload.type + "&id=" + payload.id + "&state=" + payload.state)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  sendReview(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/exam_subject_done?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getExamSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("api/v2/exam/exam_subject_exam/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_EXAM_SUBJECT", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getExamSubjectFile({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("api/v2/exam/exam_subject_file_id?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_EXAM_SUBJECT_FILE", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getAllExamSubject({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject_list")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_EXAM_SUBJECT_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getAllSchoolSubject({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/school_subject_list")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SCHOOL_SUBJECT_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getAllMiddleSchoolSubject({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_school_subject_list")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SCHOOL_SUBJECT_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createSchoolSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/school_subject", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SCHOOL_SUBJECT_ID", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createMiddleSchoolSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/middle_school_subject", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SCHOOL_SUBJECT_ID", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSchoolSubjectDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/school_subject?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SCHOOL_SUBJECT_DETAIL", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getMiddleSchoolSubjectDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/middle_school_subject?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SCHOOL_SUBJECT_DETAIL", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSubjectDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SUBJECT_DETAIL", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getAllSampleList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_sample")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_ALL_SAMPLE_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionMultipleList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_multiple/All")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_QUESTION_MULTIPLE_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getProblemList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/textbook_problem_title?publisher_id=" + payload.publisher_id + "&textbook_id=" + payload.textbook_id + "&subject_type_id=" + payload.subject_type_id + "&subject_detail_type_id=" + payload.subject_detail_type_id)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_PROBLEM_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  setQuestionMultipleWidth(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_multiple/width", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getFileBeforeCreate({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject_file?id=" + payload.id + "&id_type=1&type=" + payload.type)
        .then((response) => {
          console.log(response);
          if (response.data.result == 'success') {
            commit("SET_FILE_INFO", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getFile({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject_file?id=" + payload.id + "&type=" + payload.type)
        .then((response) => {
          console.log(response);
          if (response.data.result == 'success') {
            commit("SET_FILE_INFO", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // payload: {id: int}
  getAiQuestionFile(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/question_detail_png_list/download", payload, { responseType: 'blob' }).then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a')
        link.href = url;
        link.setAttribute('download', payload.file_name);
        document.body.appendChild(link);
        link.click();
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getAiAnswerFile(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/answer_detail_png_list/download", payload, { responseType: 'blob' }).then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a')
        link.href = url;
        link.setAttribute('download', payload.file_name);
        document.body.appendChild(link);
        link.click();
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // getFile({
  //   commit
  // }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.post("/api/v2/exam/exam_subject_file/download?id=" + payload.id + "&type=" + payload.type, {responseType: 'blob'}).then((response)=>{
  //       console.log(response)
  //       const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
  //       const link = document.createElement('a')
  //       link.href = url;
  //       link.setAttribute('download', '123123');

  //       document.body.appendChild(link);
  //       link.click();
  //       resolve(response)
  //     })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  downloadFile(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/exam_subject_file/download", payload, { responseType: 'blob' }).then((response) => {

        console.log(response);
        // if(request.getHeader("User-Agent").contains("Firefox")) {
        //   response.setHeader("Content-Disposition", "attachment;filename=\"" + new String(item.getName().getBytes("UTF-8"), "ISO-8859-1") + "\";");
        // } {
        //   console.log(URLEncoder.encode(item.getName(), "utf-8"))
        //   response.setHeader("Content-Disposition", "attachment;filename=\"" + URLEncoder.encode(item.getName(), "utf-8") + "\";");
        // }
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a')
        // console.log(contentDisposition)
        // if (contentDisposition) {
        //   const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
        //   if (fileNameMatch)
        //     [ , fileName ] = fileNameMatch.split('=');
        // }
        link.href = url;
        link.setAttribute('download', payload.file_name);

        document.body.appendChild(link);
        link.click();
        // const filename = replaceAll(decodeURI(response.headers.filename), '+', ' ')
        resolve(response)
      })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadFileMulti(context, payload) {
    var list = null
    return new Promise((resolve, reject) => {
      for (var i in payload.file_name) {
        list = {
          file_name: payload.file_name[i],
          file_path: payload.file_path + '/' + payload.file_name[i]
        }
        axios.post("/api/v2/exam/exam_subject_file/download", list, { responseType: 'blob' }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a')
          link.href = url;
          // const filename = replaceAll(decodeURI(response.headers.filename), '+', ' ')
          link.setAttribute('download', list.file_name);

          document.body.appendChild(link);
          link.click();
          resolve(response)
        })
          .catch((error) => {
            reject(error)
          })
      }
    })
  },
  deleteExamSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/exam_subject/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteExam(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/exam/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getLiteratureList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/literature")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_LITERATURE_LIST", response.data.data_list)
            resolve(response.data.data_list)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createLiterature(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/subject/literature", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            resolve(response.data.data)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getBookExamInfo({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/all_subject_exam?id=" + payload.id + '&type=' + payload.type)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_ACTIVE_BOOK_SUBJECT', response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSubjectCodeInfo({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/subject-master")
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_SUBJECT_CODE_LIST", response.data.data_list)
            resolve(response.data.data_list)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  setQuestionCopyItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_head", {
        "head_question_id": payload.head_question_id,
        "question_id": payload.question_id
      })
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_HEAD_QUESTION_ID', payload.index)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  setAutoQuestionCopyItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/auto_question_head", {
        "head_question_id": payload.head_question_id,
        "question_id": payload.question_id
      }).then((response) => {
        resolve(response)
        if (response.data.result == 'success') {
          commit('SET_HEAD_QUESTION_ID', payload.index)
          // resolve(response)
        }
        // reject(response)
      })
      // .catch((error) => {
      //   reject(error)
      // })
    })
  },
  deleteQuestionCopyItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_head_return", {
        question_id: payload.question_id
      })
        .then((response) => {
          if (response.data.result == 'success') {
            commit('DELETE_HEAD_QUESTION_ID', payload.index)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteAutoQuestionCopyItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/auto_question_head_return", {
        question_id: payload.question_id
      })
        .then((response) => {
          if (response.data.result == 'success') {
            commit('DELETE_HEAD_QUESTION_ID', payload.index)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createMiddleSchoolEarly({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/middle_school_subject", payload)
        // axios.post("/api/v2/exam/middle_school_subject" , payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },


  // 관리>시험관리:필터 문제 아이디 검색(해당 문제가 있는 시험으로 이동)
  getSubjectInfoQuestionId({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("api/v2/exam/question_id/subject_info?question_id=" + payload).then((response) => {
        resolve(response.data)
      })
    })
  },
  //모의고사 원본 리스트 
  getNationalExamList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject/ori_list").then((response) => {
        if (response.data.result == 'success') {
          commit("SET_NATIONAL_EXAM_LIST", response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  //모의고사 변형시험정보 리스트
  getMutateExamList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject/pro?exam_subject_id=" + payload).then((response) => {
        if (response.data.result == 'success') {
          commit("SET_NATIONAL_MUTATE_EXAM_LIST", response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  //모의고사 변형시험 생성(임의생성)
  createMutateExam({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/exam_subject/pro", payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  //모의고사 변형시험 정보 등록완료(최종생성)
  createMutateExamDone(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/exam_subject/pro", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  //생성된 모의고사 변형시험 정보 가져오기
  getMutateExam({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject?id=" + payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 변형시험 삭제
  deleteMutateExam(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/exam_subject/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getCategoryList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/category/-1")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_CATEGORY_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
