/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import axios from "../../http/axios/index.js"

export default {
  SET_USER_SEARCH_KEYWORD(state, payload) {
    state.keyword = payload
  },
  SET_ACTIVE_USER_TAB(state, payload) {
    state.activeTab = payload
  },
  SET_INSIGHT_INFO(state, payload) {
    state.insightInfo = payload
  },
  SET_ENTER_INFO(state, payload) {
    state.enterInfo = payload
  },
  SET_CLASS_INFO(state, payload) {
    state.classInfo = payload
  },
  SET_ACTIVE_USER_LIST(state, payload) {
    state.activeUserList = payload
  },
  SET_ALL_USER_LIST(state, payload) {
    state.userList = payload
  },
  SET_ALARM_LIST(state, payload) {
    state.alarmList = payload
  },
  SET_HOLIDAY_LIST(state, payload) {
    state.eventList = []
    // state.holidayList = []
    var response = payload.response.body.items.item
    if(response) {
      if(Array.isArray(response)) {
        for(var i in response) {
          // Vue.set(state.eventList, i, {
          Vue.set(state.eventList, i, {
            detail: response[i].dateName,
            date: response[i].locdate,  
            type: 0,
            require_hour: null
          })
        } 
      } else {
        Vue.set(state.eventList, 0, {
        // Vue.set(state.holidayList, 0, {
          detail: response.dateName,
          date: response.locdate,
          type: 0,
          require_hour: null
        })
      }
    }
    console.log(state.eventList)
  },
  SET_EVENT_LIST(state, payload) {
    state.eventList = payload
    // for(var i in payload) {
    //   state.eventList.push(payload[i])
    // }
    console.log(state.eventList)
  },
  SET_USER_WORK_HOURS(state, payload) {
    state.workHours = payload
  },
  SET_ACTIVE_MONTH(state, payload) {
    state.activeMonth = payload
  },
  SET_USER_DONE_LIST(state, payload) {
    console.log(payload)
    state.enterList = payload.enter_list
    state.reviewList = payload.review_list
  },
  SET_USER_DETAIL(state, payload) {
    state.userDetail = payload
  },
  SET_ACCOUNT_LIST(state, payload) {
    state.accountList = payload
  },
  SET_CAUTION_LIST(state, payload) {
    if(state.userDetail) {
      Vue.set(state.userDetail.basic_information, 'warning_point', payload)
    }
  },
  SET_USER_SUBJECT(state, payload) {
    console.log(state.userDetail.basic_information)
    Vue.set(state.userDetail.basic_information.user, 'class_type', payload)
  },
  SET_USER_WORK_TIME(state, payload) {
    state.userWorkTime = payload
  }
}
