/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  getUnivFilterData({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("https://filledyou.com:7770/api/v2/tar_univ/consulting/search/list")
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response);
            commit("SET_ALL_UNIV_LIST", response.data.data.region_list);
            commit('SET_ALL_MAJOR_LIST', response.data.data.keyword_list);
            // commit('SET_ALL_UNIV_MAJOR_LIST', response.data.data.univ_major_list);
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  searchUniv({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("https://filledyou.com:7770/api/v2/tar_univ/user/consulting?user_id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit("SET_ALL_UNIV_MAJOR_LIST", response.data.data);
            commit("SET_UNIV_SEARCH_RESULT", response.data.data.slice(0, 50));
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // 모의고사 리스트
  getExamList({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`https://filledyou.com:7770/api/v2/tar_univ/consulting_exam_list?user_id=${payload.user_id}&year=${payload.year}`).then((response) => {
        if (response.data.result == 'success') {
          console.log(response);
          // commit('SET_EXAM_TAB_INDEX', 0) //영상촬영용으로 임시주석처리함
          commit('SET_ALL_EXAM_LIST', response.data.data)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  // 모의고사 분석 문제 리스트
  getExamQuestionList({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("https://filledyou.com:7770/api/v2/tar_univ/consulting_exam?choice_subject_id=" + payload).then((response) => {
        if (response.data.result == 'success') {
          console.log(response);
          commit('SET_QUESTION_LIST', response.data.data)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  // 유형 분석 문제 리스트
  getCategoryQuestionList({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`https://filledyou.com:7770/api/v2/tar_univ/consulting_exam_category?choice_subject_id=${payload.choice_subject_id}`).then((response) => {
        if (response.data.result == 'success') {
          console.log(response);
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  // 유저정보
  getUserInfo({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`https://filledyou.com:7770/api/v2/tar_univ/consulting?user_id=${payload}`).then((response) => {
        if (response.data.result == 'success') {
          commit('SET_USER_SCHOOL', response.data.user_info);
          commit('SET_USER_CONSULT_CONTENT', response.data.data);
          // console.log(response);
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },






  // ai 입시 컨설팅 상품 리스트
  aiConsultingProductList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:7771" : "https://filledyou.com:7770";
      axios.get(`${baseURL}/api/v2/tar_univ/consulting_service`).then((response) => {
        commit('SET_AI_CONSULTING_PRODUCT_LIST', response.data.data);
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  // ai 입시 컨설팅 신청
  applyAiConsulting(context, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:7771" : "https://filledyou.com:7770";
      axios.post(`${baseURL}/api/v2/tar_univ/consulting_service`, payload).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
}
