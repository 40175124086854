/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Day from 'dayjs'

export default {
//   subjectList: state => state.subjectCode.filter((code)=> {
//     if(!state.gradeId) {
//       return state.subjectCode
//     } else {
//       return code.grade == state.gradeId
//     }
//   }),
}
