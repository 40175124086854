/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  allUserList: [],
  userList: [],
  activeUserList: [],
  keyword: null,
  insightInfo: null,
  enterInfo: null,
  classInfo: null,
  alarmList: [],
  activeTab: {
    text: '전체',
    level: []
  },
  userDetail: null,
  accountList: [],
  holidayList: [],
  eventList: [],
  userWorkTime: null,
  workHours: null,
  activeMonth: null,
  reviewList: [],
  enterList: []
}
