/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {
  enterExamAll: state => state.exam.allowExamList.filter((item)=> {
    return (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true)
  }),
  enterExamUser: state => state.exam.userAllowList.filter((item)=> {
    return (state.exam.targetUser.length ? state.exam.targetUser.includes(item.user_name) : true) && 
    (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
    (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
    ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
    ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true)
  }),
  enterUserDone: state => state.exam.userDoneList.filter((item)=> {
    return (state.exam.targetUser.length ? state.exam.targetUser.includes(item.user_name) : true) && 
    (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
    (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
    ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
    ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true)
  }),
  enterDone: state => state.exam.adminDoneList.filter((item)=> {
    return (state.exam.targetUser.length ? state.exam.targetUser.includes(item.user_name) : true) && 
    (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
    (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
    ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
    ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true)
  }),
  schoolExamAll: state => state.exam.schoolSubjectAll.filter((item)=> {
    return (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      (state.exam.activeSemester.length ? state.exam.activeSemester.includes(item.detail) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true)
  }),
  filterBookSubject: state => state.book.bookSubject.filter((item)=> {
    return ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true)
  }),
  classExamAll: state => state.type.typeList.filter((item)=> {
    return (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
    (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
    (state.exam.targetUser.length ? state.exam.targetUser.includes(item.teacher_name) : true) && 
    ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
    ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true) &&
    ((state.exam.activeClass.length) ? ((state.exam.activeClass.length != 2) ? state.exam.activeClass.includes(item.class_type) : true) : true)
  }),
  classExamUser: state => state.type.userList.filter((item)=> {
    return (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
    (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
    (state.exam.targetUser.length ? state.exam.targetUser.includes(item.teacher_name) : true) && 
    ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true) &&
      ((state.exam.activeClass.length) ? ((state.exam.activeClass.length != 2) ? state.exam.activeClass.includes(item.class_type) : true) : true)
  }),
  classExamDone: state => state.type.doneList.filter((item)=> {
    return (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
      (state.exam.targetUser.length ? state.exam.targetUser.includes(item.teacher_name) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true) &&
      ((state.exam.activeClass.length) ? ((state.exam.activeClass.length != 2) ? state.exam.activeClass.includes(item.class_type) : true) : true)
  }),
  activeAdminList: state => Object.keys(Object.fromEntries(
    state.adminReview.reviewList.map((item)=> {
      return [item.admin_name, null]
    })
  )).sort((a, b)=> {
    return (a == 'null') - (b == 'null')
  }),
  activeTeacherList: state => Object.keys(Object.fromEntries(
    state.type.userList.map((item)=> {
      return [item.teacher_name, null]
    })
  )).sort((a, b)=> {
    return (a == 'null') - (b == 'null')
  }),
  // )).filter((item) => {
  //   return item != 'null'
  // }),
  reviewExamAll: state => state.adminReview.reviewList.filter((item)=> {
    return ((item.test_name ? ((item.test_name == state.exam.searchKeyword.text) || item.test_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      (state.exam.targetUser.length ? state.exam.targetUser.includes(item.user_name) : true) &&
      (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeAdmin.length) ? (state.exam.activeAdmin.includes(String(item.admin_name))) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true))
  }),
  reviewExamUser: state => state.adminReview.reviewList.filter((item)=> {
    return (item.admin_check == 2) && 
      ((item.test_name ? ((item.test_name == state.exam.searchKeyword.text) || item.test_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) && 
      (state.exam.targetUser.length ? state.exam.targetUser.includes(item.user_name) : true) && 
      ((state.userInfo.level == 10) ? true : (state.exam.showAllReview ? true : (state.userInfo.uid == item.admin_user))) &&
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeAdmin.length) ? (state.exam.activeAdmin.includes(String(item.admin_name))) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true))
  }),
  reviewExamDone: state => state.adminReview.reviewList.filter((item)=> {
    return (item.admin_check >= 3 && item.admin_check <= 4) && 
      (state.exam.targetUser.length ? state.exam.targetUser.includes(item.user_name) : true) &&
      ((state.userInfo.level == 10) ? true : (state.exam.showAllReview ? true : (state.userInfo.uid == item.admin_user))) &&
      ((item.test_name ? ((item.test_name == state.exam.searchKeyword.text) || item.test_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) && 
      (state.exam.targetUser.length ? state.exam.targetUser.includes(item.user_name) : true) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeAdmin.length) ? (state.exam.activeAdmin.includes(String(item.admin_name))) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true))
  }),
  bugExamAll: state => state.bug.examList.filter((item)=> {
    return (item.test_name ? ((item.test_name == state.exam.searchKeyword.text) || item.test_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true) &&
      ((state.exam.activeClass.length) ? ((state.exam.activeClass.length != 2) ? state.exam.activeClass.includes(item.class_type) : true) : true)
  }),
  bugExamUser: state => state.bug.bugQuestionList.filter((item)=> {
    return (item.test_name ? ((item.test_name == state.exam.searchKeyword.text) || item.test_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      (state.exam.activeType.length ? state.exam.activeType.includes(item.exam) : true) &&
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true) &&
      ((state.exam.activeClass.length) ? ((state.exam.activeClass.length != 2) ? state.exam.activeClass.includes(item.class_type) : true) : true)
  }),
  bugExamDone: state => state.bug.doneList.filter((item)=> {
    return (item.test_name ? ((item.test_name == state.exam.searchKeyword.text) || item.test_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      (state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == item.year) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true) &&
      ((state.exam.activeClass.length) ? ((state.exam.activeClass.length != 2) ? state.exam.activeClass.includes(item.class_type) : true) : true)
  }),
  // 관리>시험 관리 우측 필터 (문제id로 검색할 경우 여기서 필터 안하고 ExamList(C:\Users\filledyou9\Desktop\pulda_net\pulda_home\src\views\pages\web\admin-exam-all\ExamList.vue)의 43line 쯤에 조건 추가함)
  examAll: state => state.exam.allTypeExam.filter((exam)=> {
    return state.exam.searchType==1 ? true : ((exam.name ? ((exam.name == state.exam.searchKeyword.text) || exam.name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) || ((exam.ori_id && exam.detail) ? (exam.detail.includes(state.exam.searchKeyword.text) || state.exam.searchKeyword.text.includes('변형')) : (state.exam.searchKeyword.text ? false : true))) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(exam.grade_id) : true) && 
      (state.exam.activeType.length ? state.exam.activeType.includes(exam.type) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == exam.year) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == exam.subject_type_id) : true)
  }),
  stagingExamList: state => state.exam.stagingExamList.filter((exam)=> {
    return state.exam.searchType==1 ? true : ((exam.name ? ((exam.name == state.exam.searchKeyword.text) || exam.name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) || ((exam.ori_id && exam.detail) ? (exam.detail.includes(state.exam.searchKeyword.text) || state.exam.searchKeyword.text.includes('변형')) : (state.exam.searchKeyword.text ? false : true))) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(exam.grade_id) : true) && 
      (state.exam.activeType.length ? state.exam.activeType.includes(exam.type) : true) && 
      ((state.exam.activeYear != '연도별') ? (state.exam.activeYear == exam.year) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == exam.subject_type_id) : true)
  }),
  nationalSubject: state => state.exam.examSubjectAll.filter((exam)=> {
    return (exam.test_name ? ((exam.test_name == state.exam.searchKeyword.text) || exam.test_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(exam.grade_id) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == exam.subject_type_id) : true)
  }),
  nationalMutate: state => state.exam.mutateSubject.filter((exam)=> {
    return (exam.test_name ? ((exam.test_name == state.exam.searchKeyword.text) || exam.test_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(exam.grade_id) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == exam.subject_type_id) : true)
  }),
  nationalReview: state => state.exam.reviewList.filter((exam)=> {
    return (exam.test_name ? ((exam.test_name == state.exam.searchKeyword.text) || exam.test_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(exam.grade_id) : true) &&
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == exam.subject_type_id) : true)
  }),
  filteredUser: state => state.admin.userList.filter((user)=> {
    return (state.exam.targetUser.length ? state.exam.targetUser.includes(user.name) : true) &&
      (state.exam.activeWork.length ? state.exam.activeWork.includes(user.level) : true)
  }),
  sampleList: state => state.exam.allSampleList.filter((item)=> {
    return ((state.exam.activeType.length ? state.exam.activeType.includes(item.type) : true) && 
      (state.exam.activeGrade.length ? state.exam.activeGrade.includes(item.grade_id) : true) && 
      ((state.exam.activeFilterSub.name != '과목별') ? (state.exam.activeFilterSub.subject_type_id == item.subject_type_id) : true))
  }),
  productAll: state => state.product.productList.filter((product)=> {
    return (product.goods_name ? ((product.goods_name == state.exam.searchKeyword.text) || product.goods_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) &&
        (state.exam.activeCategory.length ? state.exam.activeCategory.includes(product.category1_seq) : true) &&
        (state.exam.isProduct.length ? state.exam.isProduct.includes(product.display) : true) &&
        (state.exam.isCreate.length ? state.exam.isCreate.includes(product.auto_registered) : true)
        && (state.product.productSearch.length&&product.goods_name!=null&&product.brand_name!=null) ? (
          (product.goods_name == state.product.productSearch) || product.goods_name.includes(state.product.productSearch)
          ||
          (product.brand_name == state.product.productSearch) || product.brand_name.includes(state.product.productSearch)
        ) : true && (product.brand_name == null ? false : true)
  }),
  productHide: state => state.product.hiddenList.filter((product)=> {
    return (product.goods_name ? ((product.goods_name == state.exam.searchKeyword.text) || product.goods_name.includes(state.exam.searchKeyword.text)) : (state.exam.searchKeyword.text ? false : true)) &&
        (state.exam.activeCategory.length ? state.exam.activeCategory.includes(product.category1_seq) : true)
        && (state.product.productHideSearch.length&&product.goods_name!=null&&product.brand_name!=null) ? (
          (product.goods_name == state.product.productHideSearch) || product.goods_name.includes(state.product.productHideSearch)
          ||
          (product.brand_name == state.product.productHideSearch) || product.brand_name.includes(state.product.productHideSearch)
        ) : true
  }),
}

export default getters
