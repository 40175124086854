/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  changeBanner: true,
  currentSectionIndex: 0,
  bannerImgSrc: require("@/assets/images/main_v2/main_img.jpg"),
  reviewHeight: 0,
  premiumHeight: 0,
}
