/*=========================================================================================
  File Name: moduleUserManagement.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleMainState.js'
import mutations from './moduleMainMutations.js'
import actions from './moduleMainActions.js'
import getters from './moduleMainGetters.js'

export default {
		namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
