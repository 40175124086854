/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  filteredUser: state => JSON.parse(JSON.stringify(state.activeUserList)).filter((item) => {
    return (item.name == state.keyword || item.name.includes(state.keyword)) || (item.email == state.keyword || item.email.includes(state.keyword))
  }),
}
