/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"

const mutations = {
  SET_SCROLL_HEIGHT(state , payload) {
    state.scrollY = payload
  },
  UPDATE_USER_INFO(state, payload) {
    state.userInfo = payload
  },
  UPDATE_FIRST_USER(state, payload) {
    state.firstUser = payload
  },
  UPDATE_WORK_TYPE(state, payload) {
    state.workType = payload
  },
  UPDATE_CUSTOMER_INFO(state, payload) {
    state.customerInfo = payload
  },
  SET_SNACK_BAR_INFO(state, payload) {
    state.snackBar = payload
  },
  DELETE_SNACK_BAR(state, payload) {
    Vue.delete(state.snackBar , payload)
  },
  SET_AUDIO_FILE(state, payload) {
    state.audioFile = payload
  },
  SET_SCROLL_TOP(state, payload) {
    state.scrollTop = payload
  },
  TOGGLE_SWITCH(state, payload) {
    state.switchState = payload
  },
  SET_SEARCH_KEYWORD(state, payload) {
    state.keyword = payload
  },
  SET_INNER_WIDTH(state, payload) {
    state.innerWidth = payload
  },
  HANDLE_IS_MOBILE(state, bool) {
    state.isMobile = bool
  },
  SET_VERTICAL_NOTCH_HEIGHT(state, payload) {
    state.notchHeight = payload
  },
  CHANGE_USER_ALARM(state, payload) {
    Vue.set(state.userInfo, 'alarm', payload)
  },
  SET_PAYMENT_SCROLL_Y(state, payload) {
    state.paymentScrollY = payload;
  },
  SET_PAYMENT_TYPE(state, payload) {
    state.paymentType = payload;
  },
  SET_PAYMENT_USER_NAME(state, payload) {
    state.paymentUserName = payload;
  },}

  export default mutations
