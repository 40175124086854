/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"
import state from "../state.js"
import store from '@/store/store.js'

//  ::aiExam/actions,store.commit("exam/SET_EXAM_SUBJECT_LIST", response.data.data_list)
export default {  //  ::aiExam/actions  
  // AI > 생성(고등) > 기출 AI ... [
  // 생성된 시험의 정보 가져오기
  getSubjectDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/auto_exam_subject?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            store.commit("exam/SET_SUBJECT_DETAIL", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // (생성된 시험 가져오는거)
  getAllExamSubject({ 
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/auto_exam_subject_list").then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_EXAM_SUBJECT_LIST", response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // (생성된 시험 정보 추가 및 수정 후 완료)
  editExamSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/auto_exam_subject", payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // (완료된 시험 입력 허용으로 제출)
  sendReview(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/auto_exam_subject_done?id=" + payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // [시험 만들기] (시험 만들기 전 동작 순서1)
  createExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/exam", payload).then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_ACTIVE_EXAM", response.data.data)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // [시험 만들기] (시험 만들기 전 동작 순서2)
  createExamSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/auto_exam_subject", payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // [시험 만들기] (시험 만들기 전 동작 순서3)
  getExamSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("api/v2/exam/auto_exam_subject_exam/" + payload).then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_EXAM_SUBJECT", response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // [시험 만들기] (시험id 없을 경우 사용)
  editExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/exam", payload).then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_ACTIVE_EXAM", response.data.data)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // [시험 만들기] (위에서 생성된 시험 개별 삭제)
  deleteExamSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/auto_exam_subject/" + payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // [시험 만들기] (위에서 생성된 시험 전체 삭제)
  deleteExam(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/auto_exam/" + payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 시험생성 만들 예정인 시험이 기존에 있는지 확인(기출, 기출AI 둘다 사용) 서로 시험이 겹치는지 확인용으로 사용됨
  getCheckCreateExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam/check?grade_id=" + payload.grade_id + "&release_time=" + payload.release_time).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // ]

  
  // AI > 문제검토 ... [
  // 문제검토 시험 전체 리스트 가져오기
  getAiExamList() {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/auto_review_exam").then((response) => {
        if (response.data.result == 'success') {
          console.log(response.data.data_list);
          if(response.data.data_list.length>0) {
            var allowedExamList = [];
            var userAllowedExamList = [];
            for(var i=0; i<response.data.data_list.length; i++) {
              // state3&&admin_check   1: 검토 대기, 2: 검토 중, 3: 문제 신고,
              //state4&&admin_check5: 검토 완료

              // state3&&admin_check   1: 검토할 시험, 2: 검토 중인 시험, 4: 검토 완료, state4&&admin_check5: 승인 완료

              if(response.data.data_list[i].state == 3) { 
                if(response.data.data_list[i].admin_check == 1) {
                  allowedExamList.push(response.data.data_list[i]);
                }else {
                  userAllowedExamList.push(response.data.data_list[i]);
                }
              }else {
                userAllowedExamList.push(response.data.data_list[i]);
              }
            }
            if(allowedExamList.length>0) store.commit("exam/SET_ALLOWED_EXAM_LIST", allowedExamList); // 검토할 시험
            // 검토할 시험을 제외한 전체 시험(검토 중인 시험, 검토 완료, 승인 완료)
            if(userAllowedExamList.length>0) store.commit("exam/SET_AI_USER_ALLOWED_EXAM_LIST", userAllowedExamList) 
          }
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // (문제검토 전체  시험 가져오는 것 같음)
  getUserAllowExam({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("api/v2/exam/auto_enter_exam_user?user_id=" + payload).then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_USER_ALLOWED_EXAM_LIST", response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // (문제검토 입력할 시험만 가져옴)
  getAllowExam({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/auto_enter_exam").then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_ALLOWED_EXAM_LIST", response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 문제 검토 시험 상태값 변경
  changeAiExamState(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v2/exam/auto_review_exam?type=${payload.type}&id=${payload.id}&admin_check=${payload.admin_check}&state=${payload.state}&mark=${payload.mark}`).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // (문제검토할 시험 입력 중인 시험으로 바꾸기)
  changeState(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/auto_enter_exam", payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // (문제검토 시험 완료 처리할때 사용?)
  changeStateDone(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/auto_enter_exam_done", payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // (문제 생성 exam/ /api/v2/exam/question/add)
  createQuestion(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/question/add", payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response.data.data)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 문제검토 문제신고
  declarationQuestionError(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/divided_image/error/report", payload).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 문제검토 문제수정 태깅 api
  modifyDividedImg(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/divided_image/modify", payload).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 문제검토 문제세팅 response: {result: 'success || fail', data: 1||0} , 1: 작업이 끝나서 이미지들이 생성되었을 경우, 0: pdf,png 받았으면서 작업중이거나 작업 시작 안됬을 때
  // response: {result: 'success || fail', data: {question_divide_state: -2||0||1, answer_divide_state: -2||0||1}}  
  // question_divide_state: 문제, answer_divide_state: 해설 ,-2: 오류, 0: 대기중, 1: 자르기완료
  getFileState(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/divided_image/state?exam_subject_id="+payload,).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }, 
  // 문제검토 문제세팅 자르기 된 압축파일
  getDividedImg(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_all_image/png?exam_subject_id="+payload.exam_subject_id+"&question_number="+payload.question_number , { responseType: 'arraybuffer' }).then((response) => {
        // 폴더에 다운로드
        // console.log(response);
        // const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        // const link = document.createElement('a')
        // link.href = url;
        // link.setAttribute('download', payload.file_name);
        // document.body.appendChild(link);
        // link.click();

        // if (response.data.result == 'success') {
        //   resolve(response)
        // }
        // reject(response)
        if(response.status)
        resolve(response)

      }).catch((error) => {
        reject(error)
      })
    })
  },
  resetDividedImg(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/divided_image/reset", payload).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error)
      })
    })
  },
  /// 문제 이미지 호출
  getDividedQuestionImg(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/divided_image/get_q_image", payload).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error)
      })
    })
  },
  /// 선지 이미지 호출
  getDividedNumberQuestionImg(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/divided_image/get_c_images", payload).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error)
      })
    })
  },
  /// 해설 이미지 호출
  getDividedSolveImg(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/divided_image/get_s_image", payload).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error)
      })
    })
  },
  /// 문제검토 완료로 바꾸기
  doneAiExam(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/auto_review_exam_done", payload).then((response) => {
        resolve(response)
      })
    })
  },
  /// 문제 및 지문과 해설 열람여부 조회  {visit_a: 지문, visit_q: 문제}
  getCheckState(context, payload) {
    // console.log(payload);
    return new Promise((resolve, reject) => {
      axios.get("api/v2/exam/question_visited_check/"+payload.id).then((response) => {
        resolve(response)
      })
    })
  }, 
  /// 문제 및 지문과 해설 조회시 호출  payload: {id: int, visited_type: 0(문제 조회했을 때)||1(해설 조회했을 때)} 
  putCheckState(context, payload) {
    // console.log(payload);
    return new Promise((resolve, reject) => {
      axios.put("api/v2/exam/question_visited?id="+payload.id+'&visited_type='+payload.visited_type).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }, 
  /// 신고된 정보들 가져오기 payload: {question_index: int, exam_subject_id: int}
  getErrorInfoList(context, payload) {  
    // console.log(payload);
    return new Promise((resolve, reject) => {
      axios.get(`api/v2/exam/question_error_report?exam_subject_id=${payload.exam_subject_id}&question_index=${payload.question_index}`).then((response) => {
        resolve(response)
      })
    })
  }, 
  /// 시험 상태값 변경 전 가능한지 여부 체크(에러 체크는 프론트에서)
  getCheckExamDone({}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v2/exam/check_exam_done?type=${payload.type}&id=${payload.exam_subject_id}`).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  /// 신고된 내용 삭제
  removeDeclaration(context, payload) { // payload: {error_id: 230, exam_type: 3,}
    // console.log(payload);
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/divided_image/error/report_cancel", {
        error_id: payload.error_id,
        exam_type: payload.exam_type,
        issue_status: 2,  // 2는 고정임
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }, 
  //신고 내용 해결 완
  errorSolved(context, payload) { // payload: {error_id: 230, exam_type: 3,}
    // console.log(payload);
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/divided_image/error/report_cancel", {
        error_id: payload.error_id,
        exam_type: payload.exam_type,
        issue_status: 1,  
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }, 
  // 문제검토 확인 완료버튼
  doneExamAdminCheck(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v2/exam/auto_review_exam/check?id=${payload.id}&type=${payload.type}`).then((response) => {
        resolve(response)
      });
    })
  },
  // 관리자 전용 넘기기 버튼 동작
  skipExam(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v2/exam/auto_review_exam_force?id=${payload.id}&type=${payload.type}`).then((response) => {
        resolve(response)
      });
    })
  },
  //]
  // 관리자 전용 반려버튼
  rejectExam(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v2/exam/auto_review_exam/rejection?type=${payload.type}&id=${payload.id}`).then((response) => {
        resolve(response)
      });
    })
  },
  // 반려 수정 완료 버튼
  rejectExamModify(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v2/exam/auto_review_exam/rejection/question_done?id=${payload.id}&type=${payload.type}&question_id=${payload.question_id}`).then((response) => {
        resolve(response)
      });
    })
  },
  //]

  
  // AI > 생성(중,고등) > 내신 AI ... [
  // ?
  createSchoolSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/auto_school_subject", payload).then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_SCHOOL_SUBJECT_ID", response.data.data)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // ? 
  deleteSchoolSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/exam/auto_school_subject/" + payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // ?
  editSchoolSubject(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/auto_school_subject", payload).then((response) => {
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 생성된 내신 시험 가져옴(중,고등 통합)
  async getAllAiExamSchoolSubject(context) {
    var middleList = [];
    var highList = [];
    await store.dispatch('aiExam/getAllSchoolSubject', 2).then((res) => {
      if(res.data.result == 'success') middleList = res.data.data_list;
    });
    console.log('중학생 생성된 시험 제대로 가져와짐');
    await store.dispatch('aiExam/getAllSchoolSubject', 3).then((res) => {
      if(res.data.result == 'success') highList = res.data.data_list;
    });
    console.log('고등학생 생성된 시험 제대로 가져와짐');
    var allSchoolList = [...middleList, ...highList];
    store.commit("exam/SET_SCHOOL_SUBJECT_LIST", allSchoolList)
    return allSchoolList;
  },
  // 생성된 내신 시험 가져옴(3: 고등, 2: 중등)
  getAllSchoolSubject(context, type) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/auto_school_subject_list?type="+type).then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_SCHOOL_SUBJECT_LIST", response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // ?
  getSchoolSubjectDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/auto_school_subject?id=" + payload).then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_SCHOOL_SUBJECT_DETAIL", response.data.data)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 출판사 가져오기 school_type2: 중학생, 3: 고등학생 {school_type: 3, subject_type_id: 1, subject_detail_type_id: 1,} +{grade_id: 1, detail: ''}
  getSchoolPublisher({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/school_subject/publisher?school_type=" + payload.school_type + '&subject_type_id=' + payload.subject_type_id + '&subject_detail_type_id=' + payload.subject_detail_type_id+(payload.school_type==2 ? `&grade_id=${payload.grade_id}&detail=${payload.detail}` : '')).then((response) => {
        if (response.data.result == 'success') {
          store.commit("exam/SET_SCHOOL_PUBLISHER", response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 교과서 가져오기 school_type2: 중학생, 3: 고등학생 {publisher_id: 1, subject_type_id: 1, subject_detail_type_id: 1, school_type: 2,} + {grade_id: 1, detail: ''}
  getPublisherSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/school_subject/textbook_subject?publisher_id=" + payload.publisher_id + "&subject_type_id=" + payload.subject_type_id + "&subject_detail_type_id=" + payload.subject_detail_type_id + "&school_type=" + payload.school_type+(payload.school_type==2 ? `&grade_id=${payload.grade_id}&detail=${payload.detail}` : '')).then((response) => {
        console.log(response)
        if (response.data.result == 'success') {
          store.commit("book/SET_PUBLISH_SUBJECT_LIST", response.data.data_list)
          resolve(response)
        }
        reject(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createMiddleSchoolSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/exam/auto_school_subject", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            store.commit("exam/SET_SCHOOL_SUBJECT_ID", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // ]
}
