/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  CHANGE_BANNER_STATE(state, payload) {
    state.changeBanner = payload
  },
  HANDLE_CURRENT_SECTION_INDEX(state, payload) {
    state.currentSectionIndex = payload
  },
  SET_BANNER_IMG(state, payload) {
    state.bannerImgSrc = payload
  },
  SET_REVIEW_HEIGHT(state, height) {
    state.reviewHeight = height
  },
  SET_PREMIUM_HEIGHT(state, height) {
    state.premiumHeight = height
  },
}
