/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  pngMap: {src: '',}, // png 단일 
  pngOverlapList: [[{src: null}, ],], // png복수 중첩 리스트
  // pngOverlapList: [[{index: 0, src: null},],], // png복수 중첩 리스트
  pngOverlapListIdx: -1,
  pngOverlapListInnerIdx: -1,
  pngList: [{index: 0, src: ''},],  // png복수
  pngListSelectIdx: -1,
  zipFileList: [], // 잘라진 문제의 압축 해제한 파일 리스트  type 0: 해설, 1: 문제, 2: 연속지문, 3: 선지(1), 4: 선지(2), 5: 선지(3), 6: 선지(4), 7: 선지(5)
  selectZipFile: null,  // {}
  editQuestion: false,
  watchAiTaing: 0,
  aiTaingState: false,
  /** 자르기 된 이미지(여기 x,y값은 서버에 보내면서 임시로 프론트에서 이미지에 바로 처리하는거임, 자르기 영역에서 사용되기 부적절한 값) */
  cutImgList: [], 
}
  