import Vue from 'vue';
import App from './App.vue'
import VueScrollTo from "vue-scrollto";
import AOS from "aos";
import "aos/dist/aos.css";

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css';
import * as MathLive from 'mathlive'
import VueMathfield from 'vue-math-field'

import Day from 'dayjs'
import axios from "./axios.js"

Vue.prototype.$http = axios

Vue.config.productionTip = false

import store from './store/store'

import router from './router/index'
import vuetify from './plugins/vuetify'

// Styles: SCSS
// import './assets/scss/Main.scss'

Vue.use(Day)
Vue.use(Vuesax)
Vue.use(VueScrollTo)
Vue.use(
    AOS.init({
        startEvent: "load",
    })
)
// Vue.use(Vuesax)
Vue.use(MathLive, VueMathfield)

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
require("dayjs/plugin/isSameOrBefore")

Day.extend(isSameOrBefore)


require('@/assets/css/font.css')

new Vue({
    router,
    store,
    vuetify,
    AOS,
    render: h => h(App)
}).$mount('#app')
