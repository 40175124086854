/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"
import jwt from "../../http/requests/auth/jwt/index.js"

export default {
  // loginJWT({ commit }, payload) {
  //     return new Promise((resolve,reject) => {
  //       jwt.login(payload.userDetails.email, payload.userDetails.password).then(response => {

  //         if(response.data.result == 'success') {
  //           // Set Token & ID
  //           commit('UPDATE_USER_INFO', response.data.userData, {root: true})
  //           if(localStorage.getItem('accessToken')) {
  //             localStorage.removeItem('accessToken')
  //           }
  //           localStorage.setItem("filleduToken", response.data.token)
  //           resolve(response)
  //         }else {
  //           reject({message: "Wrong Email or Password"})
  //         }
  //       })
  //       .catch(error => { reject(error) })
  //   })
  // },
  signupJWT(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/user/create", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  sendEmail(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/user/password/find", {"email":  payload})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
}
