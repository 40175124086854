/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  activeFilterList: [],
  univCateMainIdx: 0,
  univCateMiddleIdx: 0,
  allUnivList: [],
  allMajorList: [],
  allUnivMajorList: [],
  filteredList: [],
  univSearchResult: [],
  allExamList: [],
  examTabList: [],
  activeExamList: [],
  activeExam: {},
  activeExamIndex: 0,
  userId: '',
  examTabIndex: 1, //영상촬영용으로 1로 임시처리함 default값: 0
  userName: '',
  myUniv: {
    '가군': [],
    '나군': [],
    '다군': [],
  },
  regionList: [
    {name: '서울', full_name: '서울특별시',},
    {name: '경기', full_name: '경기도',},
    {name: '인천', full_name: '인천광역시',},
    {name: '부산', full_name: '부산광역시',},
    {name: '대구', full_name: '대구광역시',},
    {name: '광주', full_name: '광주광역시',},
    {name: '대전', full_name: '대전광역시',},
    {name: '울산', full_name: '울산광역시',},
    {name: '세종', full_name: '세종특별자치시',},
    {name: '강원', full_name: '강원도',},
    {name: '경남', full_name: '경상남도',},
    {name: '경북', full_name: '경상북도',},
    {name: '충남', full_name: '충청남도',},
    {name: '충북', full_name: '충청북도',},
    {name: '전남', full_name: '전라남도',},
    {name: '전북', full_name: '전라북도',},
    {name: '제주', full_name: '제주특별자치도',},
  ],
  majorMainList: [
    {id: 1, name: '공학',},
    {id: 2, name: '사회',},
    {id: 3, name: '교육',},
    {id: 4, name: '의학',},
    {id: 5, name: '예체능',},
    {id: 6, name: '인문',},
    {id: 7, name: '자연',},
    {id: 8, name: '기타',},
  ],
  majorMiddleList: [
    {id: 1, head_major: '공학', name: '건축', list: []},
    {id: 2, head_major: '공학', name: '교통ㆍ운송', list: []},
    {id: 3, head_major: '공학', name: '기계ㆍ금속', list: []},
    {id: 4, head_major: '공학', name: '기타', list: []},
    {id: 5, head_major: '공학', name: '산업', list: []},
    {id: 6, head_major: '공학', name: '소재ㆍ재료', list: []},
    {id: 7, head_major: '공학', name: '전기ㆍ전자', list: []},
    {id: 8, head_major: '공학', name: '정밀ㆍ에너지', list: []},
    {id: 9, head_major: '공학', name: '컴퓨터ㆍ통신', list: []},
    {id: 10, head_major: '공학', name: '토목ㆍ도시', list: []},
    {id: 11, head_major: '공학', name: '화공', list: []},
    {id: 12, head_major: '사회', name: '경영ㆍ경제', list: []},
    {id: 13, head_major: '사회', name: '법률', list: []},
    {id: 14, head_major: '사회', name: '사회과학', list: []},
    {id: 15, head_major: '교육', name: '교육일반', list: []},
    {id: 16, head_major: '교육', name: '유아교육', list: []},
    {id: 17, head_major: '교육', name: '중등교육', list: []},
    {id: 18, head_major: '교육', name: '초등교육', list: []},
    {id: 19, head_major: '교육', name: '특수교육', list: []},
    {id: 20, head_major: '의학', name: '간호', list: []},
    {id: 21, head_major: '의학', name: '약학', list: []},
    {id: 22, head_major: '의학', name: '의료', list: []},
    {id: 23, head_major: '의학', name: '치료ㆍ보건', list: []},
    {id: 24, head_major: '예체능', name: '디자인', list: []},
    {id: 25, head_major: '예체능', name: '무용ㆍ체육', list: []},
    {id: 26, head_major: '예체능', name: '미술ㆍ조형', list: []},
    {id: 27, head_major: '예체능', name: '연극ㆍ영화', list: []},
    {id: 28, head_major: '예체능', name: '음악', list: []},
    {id: 29, head_major: '예체능', name: '응용예술', list: []},
    {id: 30, head_major: '인문', name: '언어ㆍ문학', list: []},
    {id: 31, head_major: '인문', name: '인문과학', list: []},
    {id: 32, head_major: '자연', name: '농림ㆍ수산', list: []},
    {id: 33, head_major: '자연', name: '생물ㆍ화학ㆍ환경', list: []},
    {id: 34, head_major: '자연', name: '생활과학', list: []},
    {id: 35, head_major: '자연', name: '수학ㆍ물리ㆍ천문ㆍ지리', list: []},
    {id: 36, head_major: '기타', name: '자유', list: []},
  ],

  scaledScoreModal: false,
  scaledScoreModalInfo: null,
  myUnivDetailModal: false,

  userSchool: null,
  userConsultContent: [],


  // 컨설팅 요청사항 생성 창 움직일 수 있는 판단 값 , x축,y축
  consultIsPress: false,
  consultPrevPosX: -28,  // 50%
  consultPrevPosY: 34,  // 50%

  consultWatchVar: 1,

  aiConsultingProductList: [],
}