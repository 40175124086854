/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import axios from "../../http/axios/index.js"

export default {
  SET_REVIEW_SUBJECT_LIST(state, payload){
    state.reviewList = payload
  },
  SET_ACTIVE_EXAM(state, payload) {
    state.activeExam = payload
  },
  SET_EXAM_LIST(state, payload) {
    state.examList = payload
    // state.examList = payload.sort(function(a, b) {
    //   return a.id < b.id ? -1 : a.id < b.id ? 1 : 0
    // })
  },
  SET_EXAM_ITEM(state, payload){
    state.activeQuestionItem = payload
  },
  // GET_DONE_REVIEW_EDIT(state, payload){
    // if (payload.admin_check = -1) {
    //   state.doneReviewEdit.push(item)
    // }
    // console.log(this.doneReviewEdit)
  // },
  SET_QUESTION_LIST(state, payload) {
    state.questionList = payload
    // state.questionList = payload.sort(function(a, b) {
    //   return a < b ? -1 : a < b ? 1 : 0
    // })
    // state.activeIndex = 0
    // state.activeMultipleList = payload.question_list
  },
  SET_ACTIVE_GRADE(state, payload) {
    state.activeGrade = payload
  },
  SET_ACTIVE_QUESTION(state, payload){
    state.activeQuestion = payload
  },
  SET_PRE_QUESTION(state, payload){
    state.lastQuestion = payload
    // console.log(state.preQuestion)
  },
  SET_ACITVE_COMMENT_TAG(state, payload){
    state.activeCommentTag = payload.comment_tag
  },
  SET_ACITVE_COMMENT(state, payload){
    state.activeComment = payload.comment
  },
  SET_PRE_QUESTION_INDEX(state, paylaod){
    state.preQuestionIndex = payload
  },
  SET_ACTIVE_QUESTION_INDEX(state, payload){
    state.activeIndex = payload
  },
  SET_ACTIVE_SUBJECT_ID(state , payload) {
    state.activeSubjectId = payload
  },
  SAVE_EDIT_INFO(state, payload){
    state.activeQuestion = payload
  },
  CREATE_MULTIPLE(state, payload){
    state.activeMultipleList.push(payload)
  },
  EDIT_MULTIPLE(state, payload){
    const index = state.activeMultipleList.findIndex((item) => item.id == payload)
    if (index != -1) {
      state.activeMultipleList[index].data = payload.data
    }
  },
  DELETE_MULTIPLE(state, payload) {
    const index = state.activeMultipleList.findIndex((item) => item.id == payload)
    if (index != -1) {
      state.activeMultipleList.splice(index, 1)
    }
  },
  SET_ADMIN_LIST(state, payload) {
    state.adminList = [{
        name: '관리자별',
        level: 0
      }]
    for(var i in payload) {
      state.adminList.push(payload[i])
    }
  },
  SET_HEAD_QUESTION_ID(state, payload) {
    Vue.set(state.questionList[state.activeIndex + payload], 'head_question_id', state.lastQuestion.id)
    // Vue.set(state.activeQuestion, 'head_question_id', state.lastQuestion.id)
  },
  DELETE_HEAD_QUESTION_ID(state, payload) {
    Vue.set(state.questionList[payload], 'head_question_id', null)
  },
  SAVE_REVIEW(state, payload){
    state.activeReview = payload
  },
  DELETE_QUESTION(state, payload){
    const index = state.questionList.findIndex((item) => item.id == payload)
      if (index != -1) {
        state.questionList.splice(index, 1)
        if (state.questionList.length == 0) {
          state.activeQuestion = {
            id: -1
          }
        } else if (state.questionList.length == index) {
          state.activeQuestion = state.questionList[index - 1]
        } else {
          state.activeQuestion = state.questionList[index]
        }
      }
    },
  CREATE_QUESTION(state, payload){
    state.questionList.push(payload)
  },
  SAVE_COMMENT_TAG(state, payload){
    state.comment_tag_list.push(payload)
  },
  SAVE_COMMENT(state, payload){
    state.comment_list.push(payload)
  },
  COUNT_REVIEW(state, payload){
    if(payload) {
      state.count_review += payload
    } else {
      state.count_review = 0
    }
  },
  RETURN_ALL_QUESTION(state, payload) {
    state.returnAll = payload
  },
  DONE_EXAM_EDIT(state, payload) {
    state.examDone = payload
  },
  ADD_EXAM_EXAMPLE(state, payload) {
    state.addExample = payload
  },
  SET_USER_COMMENT_LIST(state, payload) {
    state.userComment = payload
  },
  SET_QUESTION_SAMPLE_LIST(state, payload) {
    state.sampleList = payload
  },
  SET_RESURN_STATE(state, payload) {
    Vue.set(state.questionList[payload.index], 'comment', payload.state)
  },
}
