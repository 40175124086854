import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
  //   {
  //   path: '/',
  //   name: 'Intro',
  //   component: () => import('@/views/pages/intro/Intro.vue'),
  // }, 
  {
    path: '',
    name: 'Home',
    component: () => import('@/layouts/FullPage.vue'),
    children: [{
      path: '/',
      // path: '/main',
      name: 'Main',
      component: () => import('@/views/pages/web/main_v2/Main.vue'),
      // component: () => import('@/views/pages/web/Main.vue'),
      meta: {
        footer: true
      }
    }, {
      path: '/consulting/main',  /// 학부모 컨설팅 신청 랜딩 페이지
      name: 'ConsultingMain',
      component: () => import('@/views/pages/consulting/ConsultingMain.vue'),
      meta: {
        footer: false
      }
    }, {
      path: '/consulting/apply',  /// 학부모 컨설팅 신청(결제) 페이지
      name: 'ApplyConsulting',
      component: () => import('@/views/pages/consulting/ApplyConsulting.vue'),
      meta: {
        footer: false
      }
    }, {
      path: '/consulting/apply/privacy-policy',  /// 학부모 컨설팅 신청 개인정보 수집 동의
      name: 'ConsultingPrivacyPolicy',
      component: () => import('@/views/pages/consulting/PrivacyPolicy.vue'),
      meta: {
        footer: false
      }
    }, {
      path: '/consulting/apply/marketing-policy',  /// 학부모 컨설팅 신청 마케팅 활용 동의
      name: 'ConsultingMarketingPolicy',
      component: () => import('@/views/pages/consulting/MarketingPolicy.vue'),

      meta: {
        footer: false
      }
    }, {
      path: '/consulting/apply/done',  /// 학부모 컨설팅 신청 결제 완료
      name: 'ApplyConsultingDone',
      component: () => import('@/views/pages/consulting/ApplyDone.vue'),

      meta: {
        footer: false
      }
    }, {
      path: '/consulting/apply/failure',  /// 학부모 컨설팅 신청 결제 실패
      name: 'ApplyConsultingFailure',
      component: () => import('@/views/pages/consulting/ApplyFailure.vue'),

      meta: {
        footer: false
      }
    }, {
      path: '/mobile/main',
      name: 'MainMobile',
      component: () => import('@/views/pages/mobile/Main.vue'),
      meta: {
        footer: true
      }
    }, {
      path: '/test',
      name: 'Test',
      component: () => import('@/views/pages/web/TestPage.vue'),
    }, {
      path: '/mobile/payment',
      name: 'PaymentMain',
      component: () => import('@/views/pages/mobile/payment/ParentRanding3.vue'),
      meta: {
        footer: false
      }
    }, {
      path: 'consulting/event/page',
      name: 'ConsultingEventPage',
      component: () => import('@/views/pages/mobile/payment/payment-type-sub/PaymentAiServiceInfo.vue'),
      meta: {
        hideHeader: true
      }
    }, {
      path: '/terms-of-service',
      name: 'Terms',
      component: () => import('@/views/pages/web/Terms.vue'),
      meta: {
        footer: true
      }
    }, {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('@/views/pages/web/Privacy.vue'),
      meta: {
        footer: true
      }
    }, {
      path: 'parents/privacy',
      name: 'ParentsPrivacy',
      component: () => import('@/views/pages/web/PrivacyParents.vue'),
      meta: {
        footer: true
      }
    }, {
      path: '/mobile/privacy',
      name: 'PrivacyMobile',
      component: () => import('@/views/pages/mobile/Privacy.vue'),
      meta: {
        footer: true
      }
    },
    // {
    //   path: '/premium-service/guide',
    //   name: 'PremiumGuideWeb',
    //   component: () => import('@/views/pages/web/PremiumServiceGuide.vue'),
    // },
    {
      path: '/login',
      name: 'LoginWeb',
      component: () => import('@/views/pages/web/Login.vue'),
    }, {
      path: '/payment',
      name: 'PaymentWeb',
      component: () => import('@/views/pages/web/Payment.vue'),
    }, {
      path: '/payment/done',
      name: 'PaymentDone',
      component: () => import('@/views/pages/web/PaymentDone.vue'),
    }, {
      path: '/payment/proceed',
      name: 'PaymentProceed',
      component: () => import('@/views/pages/web/PaymentProceed.vue'),
    }, {
      path: '/payment/fail',
      name: 'PaymentFail',
      component: () => import('@/views/pages/web/PaymentFail.vue'),
    }, {
      path: '/admin/auth/login',
      name: 'AdminLogin',
      component: () => import('@/views/pages/web/auth/Login.vue'),
      meta: {
        hideHeader: true
      }
    }, {
      path: '/admin/sign-up',
      name: 'AdminSignIn',
      component: () => import('@/views/pages/web/auth/SignUp.vue'),
      meta: {
        hideHeader: true
      }
    }, {
      path: '/admin/sign-up-success',
      name: 'AdminSignInSuccess',
      component: () => import('@/views/pages/web/auth/SignUpSuccess.vue'),
      meta: {
        hideHeader: true
      }
    },
    ]
  }, {
    path: '',
    name: 'NavMain',
    component: () => import('@/layouts/components_v2/wrap/NavMainWithoutLeft.vue'),
    children: [{
      path: '/admin/admin-consulting',
      name: 'consulting',
      component: () => import('@/views/pages/web/admin-consulting/Main.vue'),
      meta: {
        pageTitle: 'AI 컨설팅',
        nameSearch: {
          'placeholder': '컨설팅'
        },
        goodsType: false,
        goodsState: false,
        registerState: false,
        createBtn: {
          'text': '등록',
          'style': 'fill',
          'icon': false
        },
        // customBtn: [
        //   {
        //     'text': '숨김 목록',
        //     'style': 'line',
        //     'action': () => {
        //       return '/admin/admin-product/hide'
        //     },
        //     'theme': 'gray'
        //   }
        // ],
        editMenu: false
      }
    }, {
      path: '/admin/admin-consulting/exam/analyze-info',
      name: 'consulting',
      component: () => import('@/views/pages/web/admin-consulting/consulting-exam/ConsultingExamAnalyzeInfoMain.vue'),
      meta: {
        pageTitle: 'AI 컨설팅 모의고사 분석정보',
        nameSearch: {
          'placeholder': '컨설팅 모의고사 분석정보'
        },
        goodsType: false,
        goodsState: false,
        registerState: false,
        createBtn: {
          'text': '등록',
          'style': 'fill',
          'icon': false
        },
        // customBtn: [
        //   {
        //     'text': '숨김 목록',
        //     'style': 'line',
        //     'action': () => {
        //       return '/admin/admin-product/hide'
        //     },
        //     'theme': 'gray'
        //   }
        // ],
        editMenu: false
      }
    }, {
      path: '/exam/admin-question',
      name: 'Question',
      component: () => import('@/views/pages/web/admin-question/Main.vue'),
      meta: {
        pageTitle: '문제입력',
        year: true,
        subject: true,
        switch: true,
        userSearch: true,
        adminFilter: false,
        examType: true,
      }
    }, {
      path: '/exam/admin-question-ai',
      name: 'Question',
      component: () => import('@/views/pages/web/admin-question-ai/Main.vue'),
      meta: {
        pageTitle: '문제검토',
        // pageTitle: '문제검토 AI',
        year: true,
        subject: true,
        switch: true,
        userSearch: true,
        adminFilter: false,
        examType: true,
      }
    }, {
      path: '/exam/admin-type',
      name: 'Type',
      component: () => import('@/views/pages/web/admin-type/Main.vue'),
      meta: {
        pageTitle: '유형관리',
        year: true,
        subject: true,
        switch: true,
        userSearch: true,
        adminFilter: false,
        examType: true,
      }
    }, {
      path: '/exam/admin-exam-review',
      name: 'Review',
      component: () => import('@/views/pages/web/admin-exam-review/Main.vue'),
      meta: {
        pageTitle: '시험검토',
        year: true,
        subject: true,
        admin: true,
        switch: true,
        userSearch: true,
        nameSearch: {
          'placeholder': '시험 이름으로 검색'
        },
        adminFilter: true,
        examType: true,
      }
    }, {
      path: '/exam/admin-exam-all',
      name: 'ExamAll',
      component: () => import('@/views/pages/web/admin-exam-all/Main.vue'),
      meta: {
        pageTitle: '시험 관리',
        year: true,
        subject: true,
        nameSearch: {
          'placeholder': '시험 이름으로 검색',
          'placeholder1': '문제 id로 검색',
        },
        adminFilter: true,
        examType: true,
      }
    },{
      path: '/exam/school-test-paper',
      name: 'TestPaper',
      component: () => import('@/views/pages/web/school-test-paper/Main.vue'),
      meta: {
        pageTitle: '학교 시험지 관리',
        year: false,
        subject: false,
        adminFilter: false,
        examType: false,
      }
    },{
      path: '/exam/school-test-paper/detail-info/:id',
      name: 'TestPaperDetailInfo',
      component: () => import('@/views/pages/web/school-test-paper/DetailInfoMain.vue'),
      meta: {
        pageTitle: '',
        year: false,
        subject: false,
        adminFilter: false,
        examType: false,
      }
    }, {
      path: '/exam/admin-question-bug',
      name: 'Bug',
      component: () => import('@/views/pages/web/admin-question-bug/Main.vue'),
      meta: {
        pageTitle: '오류있는 시험',
        nameSearch: {
          'placeholder': '시험 이름으로 검색'
        },
        subject: true,
        examType: true,
      }
    }, {
      path: '/exam/admin-question-bug-ai',
      name: 'Bug',
      component: () => import('@/views/pages/web/admin-question-bug-ai/Main.vue'),
      meta: {
        pageTitle: '신고된 시험 AI',
        nameSearch: {
          'placeholder': '시험 이름으로 검색'
        },
        subject: true,
        examType: true,
      }
    }, {
      // path: '/exam/admin-exam/:school',
      path: '/exam/admin-exam/high',  
      name: 'Exam',
      component: () => import('@/views/pages/web/admin-exam/Main.vue'),
      meta: {
        pageTitle: '시험생성(기출)',
        nameSearch: {
          'placeholder': '시험 이름으로 검색'
        },
        subject: true,
        createBtn: {
          'text': '시험 생성',
          'style': 'line',
          'icon': true
        },
      }
    }, {
      path: '/exam/admin-exam-ai/high',
      name: 'Exam',
      component: () => import('@/views/pages/web/admin-exam-ai/Main.vue'),
      meta: {
        pageTitle: '시험생성(기출)',
        // pageTitle: '시험생성(기출 AI)',
        nameSearch: {
          'placeholder': '시험 이름으로 검색'
        },
        subject: true,
        createBtn: {
          'text': '시험 생성',
          'style': 'line',
          'icon': true
        },
      }
    }, {
      path: '/exam/admin-exam-mutate/:school',
      name: 'ExamMutate',
      component: () => import('@/views/pages/web/admin-exam-mutate/Main.vue'),
      meta: {
        pageTitle: '시험생성(기출변형)',
        year: true,
        nameSearch: {
          'placeholder': '시험 이름으로 검색(ex, 21년/수능/3월)'
        },
        subject: true
      }
    }, {
      path: '/exam/admin-question-sample',
      name: 'Sample',
      component: () => import('@/views/pages/web/admin-question-sample/Main.vue'),
      meta: {
        pageTitle: '샘플 관리',
        subject: true,
      }
    }, {
      path: '/admin/admin-user',
      name: 'AdminUser',
      component: () => import('@/views/pages/web/admin/user-list/ListMain.vue'),
      meta: {
        pageTitle: '사용자 관리',
        subject: false,
        userSearch: true,
      }
    }, {
      path: '/admin/admin-product',
      name: 'Product',
      component: () => import('@/views/pages/web/admin-product/Main.vue'),
      meta: {
        pageTitle: '상품 관리 목록',
        nameSearch: {
          'placeholder': '상품명, 브랜드명으로 검색'
        },
        goodsType: true,
        goodsState: true,
        registerState: true,
        createBtn: {
          'text': '등록',
          'style': 'fill',
          'icon': false
        },
        customBtn: [
          {
            'text': '숨김 목록',
            'style': 'line',
            'action': () => {
              return '/admin/admin-product/hide'
            },
            'theme': 'gray'
          }
        ],
        editMenu: true
      }
    }, {
      path: '/admin/admin-product/hide',
      name: 'Product',
      component: () => import('@/views/pages/web/admin-product/HideMain.vue'),
      meta: {
        pageTitle: '숨김 목록',
        goodsType: true,
        nameSearch: {
          'placeholder': '상품명, 브랜드명으로 검색'
        },
        customBtn: [
          {
            'text': '상품 목록',
            'style': 'line',
            'action': () => {
              return '/admin/admin-product'
            },
            'theme': 'gray'
          }, {
            'text': '복구',
            'style': 'fill',
            'action': () => {

            },
            'theme': 'primary'
          },
          {
            'text': '삭제',
            'style': 'line',
            'action': '',
            'theme': 'primary'
          },
        ]
      }
    },
    { 
      path: '/admin/exam/management',
      name: 'ExamManagement', component: () => import('@/views/pages/web/admin-exam-management/Main.vue'),
      meta: { pageTitle: '시험 커스텀', subject: false } },
    { path: '/exam/admin-question-edit-staging',
      name: 'StagingEdit', component: () => import('@/views/pages/web/admin-exam-management/EditStagingExam.vue')
    },
    ]
  }, {
    path: '',
    name: 'NavMain',
    component: () => import('@/layouts/components_v2/wrap/NavMainWithLeft.vue'),
    children: [{
      path: '/exam/admin-exam-record/:school',
      name: 'ExamRecord',
      component: () => import('@/views/pages/web/admin-exam-records/Main.vue'),
      meta: {
        pageTitle: '시험생성(내신)',
        year: true,
        semester: true,
        createBtn: {
          'text': '시험 생성',
          'style': 'line',
          'icon': true
        }
      }
    },{
      path: '/exam/admin-exam-record-ai/:school',
      name: 'ExamRecord',
      component: () => import('@/views/pages/web/admin-exam-records-ai/Main.vue'),
      meta: {
        pageTitle: '시험생성(내신)',
        // pageTitle: '시험생성(내신) AI',
        year: true,
        semester: true,
        createBtn: {
          'text': '시험 생성',
          'style': 'line',
          'icon': true
        }
      }
    }, {
      path: '/exam/admin-textbook/middle',
      name: 'TextbookMiddle',
      component: () => import('@/views/pages/web/admin-textbook-middle/Main.vue'),
      meta: {
        pageTitle: '시험생성(교재)',
        year: true,
      }
    }, {
      path: '/exam/admin-textbook/high',
      name: 'TextbookHigh',
      component: () => import('@/views/pages/web/admin-textbook/Main.vue'),
      meta: {
        pageTitle: '시험생성(교재)',
        year: true,
      }
    }
    ]
  },
  {
    path: '',
    name: 'NavMain',
    component: () => import('@/layouts/components_v2/wrap/NavMainWithoutLeft.vue'),
    children: [{
      path: '/admin/app-payment/main',
      name: 'appPayment',
      component: () => import('@/views/pages/web/admin-app-payment/Main.vue'),
      meta: {
        pageTitle: 'e232',
        nameSearch: {
          'placeholder': '2323'
        },
        goodsType: false,
        goodsState: false,
        registerState: false,
        createBtn: {
          'text': '등록',
          'style': 'fill',
          'icon': false
        },
        editMenu: false
      }
    },
    ]
  }, {
    path: '',
    name: 'ExamNavMain',
    component: () => import('@/layouts/ExamNavMain.vue'),
    children: [{
      path: '/admin/dashboard',
      name: 'Dashboard',
      component: () => import('@/views/pages/web/admin/dashboard/Main.vue'),
      meta: {
        pageTitle: '대시보드'
      }
    }, {
      path: '/salary-detail',
      name: 'Calculate',
      component: () => import('@/views/pages/web/admin-pay/Main.vue'),
    },
    ]
  }, {
    path: '',
    name: 'UserNavMain',
    component: () => import('@/layouts/UserNavMain.vue'),
    children: [{
      path: '/admin/user-detail',
      name: 'UserDetail',
      component: () => import('@/views/pages/web/admin/user-list/DetailMain.vue'),
      meta: {
        pageTitle: '사용자 상세'
      }
    }, {
      path: '/admin/work-calendar',
      name: 'WorkCalendar',
      component: () => import('@/views/pages/web/admin/work-calendar/Main.vue'),
      meta: {
        pageTitle: '근무 달력'
      }
    }
    ]
  }, {
    path: '',
    name: 'ExamContent',
    component: () => import('@/layouts/ExamContent.vue'),
    children: [{
      path: '/exam/admin-exam-review/examReview',
      name: 'ExamReview',
      component: () => import('@/views/pages/web/admin-exam-review/ExamReview.vue'),
    }, {
      path: '/exam/admin-bug-edit',
      name: 'BugEdit',
      component: () => import('@/views/pages/web/admin-question-bug/ExamEdit.vue'),
    },{
      path: '/exam/admin-bug-edit-ai',
      name: 'BugEdit',
      component: () => import('@/views/pages/web/admin-question-bug-ai/ExamEdit.vue'),
    }, {
      path: '/exam/admin-type-edit',
      name: 'TypeEdit',
      component: () => import('@/views/pages/web/admin-type/EditQuestion.vue'),
    }, {
      path: '/exam/admin-question-edit',
      name: 'QuestionEdit',
      component: () => import('@/views/pages/web/admin-question/ExamEdit.vue'),
    }, {
      path: '/exam/admin-question-edit-ai',
      name: 'QuestionEdit',
      component: () => import('@/views/pages/web/admin-question-ai/ExamEdit.vue'),
    }, {
      path: '/exam/admin-question-sample/:state',
      name: 'QuestionSample',
      component: () => import('@/views/pages/web/admin-question/ExamSample.vue'),
    }
      // ,{
      //   path: '/exam/a',
      //   name: 'A',
      //   component: () => import('@/views/pages/web/admin-exam-review/a.vue')
      // }
    ]
  }, {
    path: '',
    name: 'GuideNavMain',
    component: () => import('@/layouts/GuideNavMain.vue'),
    children: [{
      path: '/guide',
      name: 'Guide',
      component: () => import('@/views/pages/web/exam-guide/Main.vue'),
    }
    ]
  }, {
    path: '/post',
    name: 'duam-post',
    component: () => import('@/views/pages/mobile/post.vue'),
  }, {
    path: '',
    name: 'Parents',
    component: () => import('@/layouts/FullPageParents.vue'),
    children: [{
      path: '/parents/intro',
      name: 'ParentsIntro',
      component: () => import('@/views/pages/parents/Main.vue'),
    }
    ]
  },
  { 
    path: '/exam/admin-question-staging-info',
    name: 'StagingEdit', component: () => import('@/views/pages/web/admin-exam-management/QuestionInfo.vue')
  },

  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

