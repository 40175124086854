/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"
import http from "axios"

export default {
  getAllUserList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/user_management")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_ALL_USER_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getActiveUserList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/user_manage")
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit('SET_ACTIVE_USER_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAdminInsight({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/admin_dashboard")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_INSIGHT_INFO', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getEnterInsight({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/user_dashboard")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_ENTER_INFO', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getClassInsight({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/teacher_dashboard")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_CLASS_INFO', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUserLevel(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/common/user/level", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUserSubject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/common/user/class_type", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_USER_SUBJECT', response.data.data_list)
            console.log(response)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendUserCaution({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/common/user/warning", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_CAUTION_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  withdrawalUser(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/common/user/out/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/user_management_detail?user_id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit('SET_USER_DETAIL', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserWarningDetail(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/user/get_warning?user_id="+ payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserAccountList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/account_user?user_id=" + payload.id + "&start_date=" + payload.start_date + '&end_date=' + payload.end_date)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit('SET_ACCOUNT_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getHoliday({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      http.get("http://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/" + payload.operation + '?solMonth=' + payload.params.solMonth + '&solYear=' + payload.params.solYear + '&serviceKey=' + payload.serviceKey)
        .then((res) => {
          console.log(res)
          if (res.data.response.header.resultCode == '00') {
            commit('SET_HOLIDAY_LIST', res.data)
            resolve(res)
          }
          reject(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAlarmList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/user/alarm")
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit('SET_ALARM_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeUserAlarmState(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/common/user/alarm", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeAlarmState(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/common/user_alarm", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUserNote(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/common/user/detail", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveUserWorkTime(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/common/user/work_day", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUserEvent(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/common/admin_calendar", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getEvent({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      // axios.get("/api/v2/common/admin_calendar?year="+ payload.year + "&month=" + payload.month)
      axios.get("/api/v2/common/admin_calendar?year="+ payload.year + "&month=" + payload.month + "&user_id=" + payload.user_id)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit('SET_EVENT_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserWorkHours({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      // axios.get("/api/v2/common/admin_calendar?year="+ payload.year + "&month=" + payload.month)
      axios.get("/api/v2/common/calendar/user_work_time?user_id=" + payload.user_id + "&date=" + payload.date)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit('SET_USER_WORK_HOURS', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDoneList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      // axios.get("/api/v2/common/admin_calendar?year="+ payload.year + "&month=" + payload.month)
      axios.get("/api/v2/common/user_management_detail/finish_list?user_id=" + payload.user_id + "&date=" + payload.date)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit('SET_USER_DONE_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  
  getUserWorkTime({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/common/admin_calendar?year="+ payload.year +"&month=" + payload.month)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response)
            commit('SET_USER_WORK_TIME', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeEvent(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/common/admin_calendar", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteEvent(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/v2/common/admin_calendar/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
